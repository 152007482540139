












































































































import Vue from "vue";
import {Component} from 'vue-property-decorator';
import {namespace} from 'vuex-class'
import HomeAppBar from "@/components/AppBar.vue";
import PushListRow from "@/components/PushListRow.vue";
import PushDialog from "@/components/dialogs/push/PushDialog.vue";
import {PushModel} from "@/stores/push/types";
import {Organization, ORGANIZATIONS} from "@/enum/Organizations";
import {ROLES} from "@/enum/Roles";
import {GAMETYPES} from "@/enum/GameTypes";
import {OrgConfig, User} from "@/stores/auth/types";
import QuestionStore from "@/components/dialogs/question/QuestionStore";
import {productForIdentifier, PRODUCTS} from "@/enum/Products";

const authModule = namespace('auth');
const pushModule = namespace('push');
const infoModule = namespace('info');

@Component({
  name: 'Push',
  components: {
    HomeAppBar,
    PushListRow,
    PushDialog
  },
})
export default class Push extends Vue {
  @authModule.Getter('config') config!: OrgConfig;
  @authModule.Getter('gesellschaft') organization!: Organization;
  @authModule.Getter('user') user!: User;

  @infoModule.Action('error') showError!: Function;

  @pushModule.Action('sendEditorialPush') sendEditorialPush!: Function;

  @pushModule.Action('getAllLottoPush') fetchLottoPushs!: Function;
  @pushModule.Action('getAllEditorialPush') fetchEditorialPushs!: Function;

  // @pushModule.State('pushs') pushs!: Array<PushModel>;
  @pushModule.Mutation('showDialog') showDialog!: Function;

  $refs!: {
    pushForm: HTMLFormElement;
  };

  title = '';
  message = '';
  messageCharLimit = 180
  pushs: Array<PushModel> = []

  rules = {
    notEmpty: (value: string) => !!value || "Dieses Feld ist erforderlich",
    lengtMessage: (value: string) => value.length <= this.messageCharLimit || 'Maximale Länge überschritten'
  };
  // Table content and functions below from pushtest (superadmin only) section
  search = '';
  footerProps = {
    'items-per-page-options': [5, 10, 20, -1]
  };

  get headers() {
    return [
      {text: 'Spiel', align: 'left', value: 'game_type', sortable: true, width: '10%'},
      {text: 'Plattform', align: 'left', value: 'os', sortable: true, width: '5%'},
      {text: 'Erfolgreich\nversendet', align: 'left', value: 'successful_count', sortable: true, width: '7.5%'},
      {text: 'Nicht\nversendet', align: 'left', value: 'failure_count', sortable: true, width: '7.5%'},
      {text: 'Inhalt', align: 'left', value: 'text_message', sortable: false},
      {text: 'Sendezeit', align: 'left', value: 'created_at', sortable: true, width: '12.5%'},
      {text: 'Optionen', align: 'left', value: 'options', sortable: false, width: '5%'},
    ];
  }

  get pushItems() {
    let items = this.pushs
    switch (this.organization) {
      case ORGANIZATIONS.ST:
        items = items.filter(it => it.game_type !== GAMETYPES.KENO.displayTitle)
        break;
      default:
        items = items.filter(it => it.game_type !== GAMETYPES.BINGO.displayTitle)
        break;
    }
    return items
  }

  triggerPushConfirmation() {
    if (this.$refs.pushForm.validate()) {
      QuestionStore.poseQuestion(
          "Senden bestätigen",
          `Push wirklich senden? Dies kann nicht rückgängig gemacht werden`,
          ['Ja', 'Nein'],
          -1
      ).then((answerIndex) => {
        if (answerIndex === 0) {
          this.sendEditorialPush(
              {
                "push_title": this.preparePushTitle(),
                "push_text": this.message
              }
          ).then(() => {
            this.title = '';
            this.message = '';
            this.$refs.pushForm.reset()
          }).catch(() => {
            // handled within store, do nothing here
          });
        }
      })
    }
  }

  mounted() {
    if (this.isSuperadmin()) {
      this.gatherPushes()
    }
  }

  gatherPushes() {
    this.pushs = []

    this.fetchLottoPushs().then((res: Array<PushModel>) => {
      this.pushs.push(...res)
      this.fetchEditorialPushs().then((res: Array<PushModel>) => {
        this.pushs.push(...res)
      }).catch(() => {
        // Error triggered in store
      })
    }).catch(() => {
      // Error triggered in store
    })
  }

  onNewTestPush() {
    this.showDialog({
      push: null,
      isNew: true,
    })
  }

  preparePushTitle(): string {
    const product = productForIdentifier(this.config.product_id);

    switch (this.organization) {
      case ORGANIZATIONS.BY:
        if (product == PRODUCTS.OA) {
          return "LOTTO Bayern App News"
        } else {
          return "LOTTO Bayern: SÄPP News"
        }
      case ORGANIZATIONS.RP:
        return "LOTTO Rheinland-Pfalz: LoRA News";
      case ORGANIZATIONS.SL:
        return "ServiceApp: News";
      case ORGANIZATIONS.SH:
        return "LOTTO Schleswig-Holstein: LOTTA News";
      case ORGANIZATIONS.ST:
        return "LOTTO Sachsen-Anhalt: LOTTI News";
      case ORGANIZATIONS.BE:
        return "LOTTO Berlin: Berta News";
      default:
        // apparently push title can't be missing and can't be empty string
        return "-";
    }
  }

  isSuperadmin(): boolean {
    return this.user.role.role_id === ROLES.SUPERADMIN.role_id
  }
}






















































import Vue from "vue";
import UserDetails from "@/components/dialogs/users/UserDialog.vue";
import UserRow from "@/components/UserRow.vue";
import {namespace} from "vuex-class";
import {Component} from "vue-property-decorator";
import {UserModel} from "@/stores/users/types";

const userModule = namespace("users");

@Component({
  components: {
    UserDetails,
    UserRow
  }
})
export default class Users extends Vue {
  @userModule.Action('getAll') fetchUsers!: Function;
  @userModule.State('users') users!: Array<UserModel>;
  @userModule.Mutation('showUserDetails') showDialog!: Function;
  search = '';
  footerProps = {
    'items-per-page-options': [5, 10, 15, 20, -1]
  };

  get headers() {
    return [
      {text: '', align: 'left', width: 64, value: null, sortable: false},
      {text: 'E-Mail', align: 'left', value: 'email', sortable: true},
      {text: 'Role', align: 'left', value: 'role.name', sortable: true},
      {text: 'Optionen', align: 'center', value: '', sortable: false}
    ]
  }

  mounted() {
    this.fetchUsers()
  }

  createUser() {
    this.showDialog({user: null, isNew: true})
  }
}



























































import Vue from 'vue';
import {Component, Watch} from "vue-property-decorator";
import {namespace} from "vuex-class";

const authModule = namespace('auth');
const infoModule = namespace('info');

@Component({
  components: {
    //
  },
})
export default class Login extends Vue {
  @authModule.Action('login') login!: Function;
  @infoModule.State('loading') loading!: boolean;

  loadActive = false;
  validation = true;
  email = '';
  password = '';
  showPass = false;

  @Watch('loading')
  onLoadingChanged(val: boolean, oldVal: boolean) {
    this.loadActive = val
  }

  onLogin() {
    this.validation = true;
    this.login({email: this.email, password: this.password});
  }
}





















































import Vue from "vue";
import {Component} from 'vue-property-decorator';
import {namespace} from 'vuex-class'
import HomeAppBar from "@/components/AppBar.vue";
import QuicktippsDialog from "@/components/dialogs/QuicktippsDialog.vue";
import {QuicktippType} from "@/enum/QuicktippTypes";
import QuicktippListRow from "@/components/QuicktippListRow.vue";
import {QuicktippModel} from "@/stores/quicktipps/types";

const quicktippsModule = namespace('quicktipps');

@Component({
  name: 'Quicktipps',
  components: {
    HomeAppBar,
    QuicktippListRow,
    QuicktippsDialog
  },
})
export default class Quicktipps extends Vue {
  @quicktippsModule.Action('getAll') fetchQuicktipps!: Function;
  @quicktippsModule.State('quicktipps') quicktipps!: Array<QuicktippModel>;
  @quicktippsModule.Mutation('showQuicktippsDialog') showDialog!: Function;

  search = '';
  footerProps = {
    'items-per-page-options': [5, 10, 20, -1]
  };

  get headers() {
    return [
      {text: 'Spiel', align: 'left', value: 'game', sortable: true},
      {text: 'Laufzeit', align: 'left', value: 'duration', sortable: true},
      {text: 'Optionen', align: 'left', value: 'option', sortable: false}
    ];
  }

  mounted() {
    this.fetchQuicktipps()
  }

  onNewQuicktipp() {
    this.showDialog({
      quicktipp: null,
      isNew: true,
      context: QuicktippType.SIMPLE.identifier,
      campaignId: null
    })
  }
}

import {ActionTree, Module, MutationTree} from 'vuex'
import api from '@/api/base'
import {AxiosResponse} from "axios";
import {RootState} from "@/stores/types";
import {UserModel, UserState} from "@/stores/users/types";
import {ROUTES} from "@/constants/routes";

const getDefaultState = () => {
    return {
        users: [],
        userDialogState: {
            isNew: false,
            user: null,
            visible: false
        }
    }
};

const state = getDefaultState();

const actions: ActionTree<UserState, RootState> = {
    getAll({commit}) {
        api.get(ROUTES.ALL_USERS)
            .then((response: AxiosResponse) => {
                commit('setUsers', response)
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    addNewUser({commit, dispatch}, data: UserModel) {
        api.post(ROUTES.ALL_USERS, data)
            .then((response: AxiosResponse) => {
                commit('info/success', "Nutzer erfolgreich angelegt", {root: true});

                //Remove DocDialog
                commit('dismissUserDetails', state);

                //reload all documents
                dispatch("getAll")
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    updateExistedUser({commit, dispatch}, data: UserModel) {
        //remove user id from request data
        const userId = data.user_id;
        delete data.user_id;
        api.put(ROUTES.UPDATE_USER.replace('{user_id}', String(userId)), data)
            .then((response: AxiosResponse) => {
                commit('info/success', "Nutzer erfolgreich bearbeitet", {root: true});

                //Remove DocDialog
                commit('dismissUserDetails', state);

                //reload all documents
                dispatch("getAll")
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    deleteUser({commit, dispatch}, {userId}: { userId: string }) {
        api.delete(ROUTES.UPDATE_USER.replace('{user_id}', userId))
            .then((response: AxiosResponse) => {
                commit('info/success', "Nutzer erfolgreich gelöscht", {root: true});

                //Remove DocDialog
                commit('dismissUserDetails', state);

                //reload all documents
                dispatch("getAll")
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    }
};

const mutations: MutationTree<UserState> = {
    setUsers(state, users: Array<UserModel>) {
        state.users = users
    },
    showUserDetails(state, data: { user: UserModel | null; isNew: boolean }) {
        state.userDialogState.user = data.user;
        state.userDialogState.isNew = data.isNew;
        state.userDialogState.visible = true
    },
    dismissUserDetails(state) {
        state.userDialogState.visible = false;
        state.userDialogState.user = null;
        state.userDialogState.isNew = false
    }
};

export const users: Module<UserState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations
};























































import Vue from "vue";
import {Component} from 'vue-property-decorator';
import {namespace} from 'vuex-class'
import {CampaignDialogModel, CampaignModel} from "@/stores/campaigns/types";
import {OrgConfig} from "@/stores/auth/types";
import {productForIdentifier} from "@/enum/Products";

import HomeAppBar from "@/components/AppBar.vue";
import VDatetimePicker from "@/components/datetimepicker/DatetimePicker.vue";
import CampaignDialog from "@/components/dialogs/campaign/CampaignDialog.vue";
import CampaignListRow from "@/components/CampaignListRow.vue";
import {VALUES} from "@/constants/values";
import {Organization, ORGANIZATIONS} from "@/enum/Organizations";

const authModule = namespace('auth');
const campaignModule = namespace('campaigns');

@Component({
  components: {
    HomeAppBar,
    CampaignDialog,
    VDatetimePicker,
    CampaignListRow
  },
})
export default class Campaigns extends Vue {
  //https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-0.html#non-null-assertion-operator
  @authModule.Getter('config') config!: OrgConfig;
  @authModule.Getter('gesellschaft') organization!: Organization;

  @campaignModule.Action('getAll') fetchCampaigns!: Function;
  @campaignModule.State('campaigns') campaigns!: Array<CampaignModel>;
  @campaignModule.State('dialogState') campaignDialog!: CampaignDialogModel;
  @campaignModule.Mutation('showCampaignDialog') showDialog!: Function;

  search = '';
  datetime = new Date();
  footerProps = {
    'items-per-page-options': [5, 10, 20, -1]
  };

  get headers() {
    const headerArray = [];

    //FIXME maybe dynamic widths in future

    headerArray.push({text: 'Vorschau (Bild)', align: 'left', value: '', width: "1%", sortable: false});
    if (this.isOrgUnderBerlin() && !this.hasAdPager()) {
      headerArray.push({text: 'Dateiname', align: 'left', value: '', sortable: true});
    }
    if (!this.isOrgUnderBerlin()) {
      headerArray.push({text: 'Überschrift', align: 'left', value: 'headline', sortable: true});
      headerArray.push({text: 'Beschreibung', align: 'left', value: 'description', sortable: true});
    }
    if (this.hasAdPager()) {
      headerArray.push({text: 'Titel', align: 'left', value: 'headline', width: "10%", sortable: true});
      headerArray.push({text: 'Subtitel', align: 'left', value: 'description', width: "15%", sortable: true});
      headerArray.push({text: 'Actiontext', align: 'left', value: 'deeplink.buttonname', width: "8%", sortable: true});
    }
    headerArray.push({text: 'Start', align: 'left', value: 'start', width: "2.5%", sortable: true});
    headerArray.push({text: 'Ende', align: 'left', value: 'end', width: "2.5%", sortable: true});
    if (this.hasDeeplinks() && !this.isBrandenburg()) {
      headerArray.push({text: 'Deeplinktyp', align: 'left', value: 'deeplink.type', width: "2.5%", sortable: true});
      headerArray.push({
        text: 'Deeplink-Action',
        align: 'left',
        value: 'deeplink.action',
        width: "10%",
        sortable: true
      });
    }
    headerArray.push({text: 'Optionen', align: 'left', value: 'option', width: "1%", sortable: false});

    return headerArray;
  }

  checkCampaignLimitations(): boolean {
    switch (this.organization) {
      case ORGANIZATIONS.BB:
        return this.campaigns.length < VALUES.CAMPAIGNS_MAX_COUNT;
      default:
        return true
    }
  }

  mounted() {
    this.fetchCampaigns()
  }

  onNewCampaign() {
    this.showDialog({campaign: null, isNew: true})
  }

  hasDeeplinks() {
    return this.organization !== ORGANIZATIONS.SL;
  }

  hasAdPager(): boolean {
    const product = productForIdentifier(this.config.product_id);
    //organization check not required here but use it for now as RP is only org with it
    if (product && this.organization === ORGANIZATIONS.RP) {
      return !this.organization.siteConfig.get(product)?.hasSingularCampaign ?? false
    }
    return false
  }

  isOrgUnderBerlin(): boolean {
    return this.organization === ORGANIZATIONS.RP ||
        this.organization === ORGANIZATIONS.SL ||
        this.organization === ORGANIZATIONS.SH ||
        this.organization === ORGANIZATIONS.ST ||
        this.organization === ORGANIZATIONS.BE ||
        this.organization === ORGANIZATIONS.MV
  }

  isBrandenburg() {
    return this.organization === ORGANIZATIONS.BB;
  }
}

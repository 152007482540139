import {ActionTree, Module, MutationTree} from 'vuex'
import api from '@/api/base'
import {AxiosResponse} from "axios";
import {RootState} from "@/stores/types";
import {ROUTES} from "@/constants/routes";
import {PushModel, PushState} from "@/stores/push/types";

const getDefaultState = () => {
    return {
        pushs: [],
        dialogState: {
            isNew: false,
            push: null,
            visible: false
        }
    }
};

const state = getDefaultState();

const actions: ActionTree<PushState, RootState> = {

    sendEditorialPush({commit}, data): Promise<any> {
        return new Promise((resolve, reject) => {
            api.post(ROUTES.SEND_EDITORIAL_PUSH, data)
                .then((response: AxiosResponse) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    if (response[0].notification_log.successful_count > 0) {
                        commit('info/success', "Push erfolgreich gesendet", {root: true});
                        resolve()
                    } else {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                        // @ts-ignore
                        commit('info/error', `Push Sendung fehlgeschlagen ${response[0].fcm_log}`, {root: true});
                        reject()
                    }
                })
                .catch((reason) => {
                    if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                        commit('info/error', reason.description, {root: true})
                    } else {
                        commit('info/error', reason, {root: true})
                    }
                    reject()
                })
        })
    },

    // Below API related to pushtests

    // Types don't work very well here thus any; expected Type -> Array<PushModel>
    getAllLottoPush({commit}): Promise<any> {
        return new Promise((resolve, reject) => {
            api.get(ROUTES.GET_ALL_LOTTO_PUSH).then(response => {
                resolve(response)
            }).catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'description')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
                reject()
            })
        })
    },

    // Types don't work very well here thus any; expected Type -> Array<PushModel>
    getAllEditorialPush({commit}): Promise<any> {
        return new Promise((resolve, reject) => {
            api.get(ROUTES.GET_ALL_EDITORIAL_PUSH).then(response => {
                resolve(response)
            }).catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'description')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
                reject()
            })
        })
    },

    send_test_push({commit, dispatch}, data) {
        api.post(ROUTES.SEND_TEST_LOTTO_PUSH, data)
            .then((response: AxiosResponse) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                if (response[0].notification_log.successful_count > 0) {
                    commit('info/success', "Push Aufbereitung erfolgreich gesendet", {root: true});

                    //Remove Dialog
                    commit('dismissDialog', state);
                } else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    commit('info/error', `Push Sendung fehlgeschlagen ${response[0].fcm_log}`, {root: true});
                }
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },

    repeat_push({commit, dispatch}, data) {
        api.post(ROUTES.REPEAT_PUSH, data)
            .then((response: AxiosResponse) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                if (response[0].notification_log.successful_count > 0) {
                    commit('info/success', "Push Aufbereitung erfolgreich gesendet", {root: true});

                    //Remove Dialog
                    commit('dismissDialog', state);
                } else {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    commit('info/error', `Push Sendung fehlgeschlagen ${response[0].fcm_log}`, {root: true});
                }
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    }
};

// mutations
const mutations: MutationTree<PushState> = {
    setPushData(state, pushs: Array<PushModel>) {
        state.pushs = pushs
    },
    showDialog(state, data: { push: PushModel | null; isNew: boolean }) {
        state.dialogState.push = data.push;
        state.dialogState.isNew = data.isNew;
        state.dialogState.visible = true;
    },
    dismissDialog(state) {
        state.dialogState.visible = false;
        state.dialogState.push = null;
        state.dialogState.isNew = false;
    },
    resetState(state) {
        Object.assign(state, getDefaultState())
    }
};

export const push: Module<PushState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations
};

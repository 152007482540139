export const PUSHTYPES = {
    JACKPOT: {displayTitle: "Jackpot", name: "JACKPOT"},
    GAME_RESULTS: {displayTitle: "Gewinnzahlen", name: "GAME_RESULTS"},
    GAME_RESULT_ODDS: {displayTitle: "Gewinnquoten", name: "GAME_RESULT_ODDS"},
    REMINDER: {displayTitle: "Abgabeerinnerung", name: "REMINDER"},
    EDITORIAL: {displayTitle: "Redaktionel", name: "EDITORIAL"},
};

export interface PushType {
    displayTitle: string;
    name: string;
}

export function convertPushTypeToString(name: PushType | null): string | null {
    switch (name) {
        case PUSHTYPES.JACKPOT:
            return PUSHTYPES.JACKPOT.displayTitle;
        case PUSHTYPES.GAME_RESULTS:
            return PUSHTYPES.GAME_RESULTS.displayTitle;
        case PUSHTYPES.GAME_RESULT_ODDS:
            return PUSHTYPES.GAME_RESULT_ODDS.displayTitle;
        case PUSHTYPES.REMINDER:
            return PUSHTYPES.REMINDER.displayTitle;
        case PUSHTYPES.EDITORIAL:
            return PUSHTYPES.EDITORIAL.displayTitle;
        default:
            return null
    }
}

export function convertPushTypeFromString(name: string | null | undefined): PushType | null {
    switch (name) {
        case PUSHTYPES.JACKPOT.displayTitle:
            return PUSHTYPES.JACKPOT;
        case PUSHTYPES.GAME_RESULTS.displayTitle:
            return PUSHTYPES.GAME_RESULTS;
        case PUSHTYPES.GAME_RESULT_ODDS.displayTitle:
            return PUSHTYPES.GAME_RESULT_ODDS;
        case PUSHTYPES.REMINDER.displayTitle:
            return PUSHTYPES.REMINDER;
        case PUSHTYPES.EDITORIAL.displayTitle:
            return PUSHTYPES.EDITORIAL;
        default:
            return null
    }
}

export function convertPushTypeFromValue(value: string | null | undefined): PushType | null {
    switch (value) {
        case PUSHTYPES.JACKPOT.name:
            return PUSHTYPES.JACKPOT;
        case PUSHTYPES.GAME_RESULTS.name:
            return PUSHTYPES.GAME_RESULTS;
        case PUSHTYPES.GAME_RESULT_ODDS.name:
            return PUSHTYPES.GAME_RESULT_ODDS;
        case PUSHTYPES.REMINDER.name:
            return PUSHTYPES.REMINDER;
        case PUSHTYPES.EDITORIAL.name:
            return PUSHTYPES.EDITORIAL;
        default:
            return null
    }
}

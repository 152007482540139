import {ActionTree, Module, MutationTree} from 'vuex'
import api from '@/api/base'
import {AxiosResponse} from "axios";
import {RootState} from "@/stores/types";
import {AppversionModel, AppversionState} from "@/stores/appversions/types";
import {ROUTES} from "@/constants/routes";

const getDefaultState = () => {
    return {
        appversions_ios: [],
        appversions_android: [],
        appDialogState: {
            isNew: false,
            appversion: null,
            visible: false,
            tab: ''
        }
    }
};

const state = getDefaultState();

const actions: ActionTree<AppversionState, RootState> = {
    getAll({commit}, data) {
        api.get(ROUTES.APPVERSIONS_GET_ALL)
            .then((response: AxiosResponse) => {
                commit('setAppversions', response)
                if (state.appDialogState.visible) {
                    commit('dismissAppversionDialog', state);
                }
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'description')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    create({commit, dispatch}, data: AppversionModel) {
        api.post(ROUTES.APPVERSIONS_CREATE_NEW, data)
            .then((response: AxiosResponse) => {
                commit('info/success', "Appversion erfolgreich angelegt", {root: true});

                //Remove AppversionDialog
                commit('dismissAppversionDialog', state);

                //reload all appversions
                dispatch("getAll")
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    update({commit, dispatch}, data) {
        const appId = data.id;
        delete data.id;
        api.put(ROUTES.APPVERSIONS_UPDATE_EXISTING.replace('{app_id}', String(appId)), data)
            .then((response: AxiosResponse) => {
                commit('info/success', "Dokument erfolgreich bearbeitet", {root: true});

                //Remove AppversionDialog
                commit('dismissAppversionDialog', state);

                //reload all appversions
                dispatch("getAll")
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    delete({commit, dispatch}, {appId}: { appId: string }) {
        api.delete(ROUTES.APPVERSIONS_DELETE_EXISTING.replace('{app_id}', appId))
            .then((response: AxiosResponse) => {
                commit('info/success', "Appversion erfolgreich gelöscht", {root: true});

                //reload all appversions
                dispatch("getAll")
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    }
}

// mutations
const mutations: MutationTree<AppversionState> = {
    setAppversions(state, appversion: Array<AppversionModel>) {
        state.appversions_ios.splice(0);
        state.appversions_android.splice(0);
        appversion.map(function (app, key) {
            if (app.platform === 'ios') {
                state.appversions_ios.push(app);
            } else if (app.platform === 'android') {
                state.appversions_android.push(app);
            }
        });
    },
    showAppversionDialog(state, data: { appversion: AppversionModel | null; isNew: boolean; tab: string }) {
        state.appDialogState.appversion = data.appversion;
        state.appDialogState.isNew = data.isNew;
        state.appDialogState.visible = true
        state.appDialogState.tab = data.tab;
    },
    dismissAppversionDialog(state) {
        state.appDialogState.visible = false;
        state.appDialogState.appversion = null;
        state.appDialogState.isNew = false;
    },
}

export const appversions: Module<AppversionState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations
};

import {ActionTree, Module, MutationTree} from 'vuex'
import api from '@/api/base'
import {AxiosResponse} from "axios";
import {RootState} from "@/stores/types";
import {DeeplinkModel, DeeplinkState} from "@/stores/deeplinks/types";
import {QuicktippModel} from "@/stores/quicktipps/types";
import {ROUTES} from "@/constants/routes";

const getDefaultState = () => {
    return {
        deeplink: {
            id: -1,
            action: '',
            buttonname: '',
            game: '',
            headline: '',
            subline: '',
            type: '',
            quicktipp: {
                id: -1,
                game: '',
                type: '',
                duration: -1,
                draw_day_type: '',
                quicktipp_value: -1,
                bonus_games: [],
                shares: null,
                keno_type: null,
                keno_bet: null
            }
        }
    }
};

const state = getDefaultState();

const actions: ActionTree<DeeplinkState, RootState> = {
    get({commit, dispatch}, campaignId: number) {
        api.get(ROUTES.CAMPAIGNS_DEEPLINK_UNIVERSAL.replace('{campaign_id}', String(campaignId)))
            .then((response: AxiosResponse) => {
                commit('setDeeplink', response);
                commit('campaigns/setDeeplink', response, {root: true})
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'description')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },

    create({commit, dispatch}, data: { campaignId: number; deeplink: DeeplinkModel }) {
        return new Promise((resolve, reject) => {
            api.post(ROUTES.CAMPAIGNS_DEEPLINK_UNIVERSAL.replace('{campaign_id}', String(data.campaignId)), data.deeplink).then(res => {
                commit('setDeeplink', res);
                commit('campaigns/setDeeplink', res, {root: true});
                resolve(res);
            }).catch(err => {
                if (Object.prototype.hasOwnProperty.call(err, 'description')) {
                    commit('info/error', err.description, {root: true})
                } else {
                    commit('info/error', err, {root: true})
                }
                reject(err)
            })
        })
    },

    update({commit, dispatch}, data: { campaignId: number; deeplink: DeeplinkModel }) {
        api.put(
            ROUTES.CAMPAIGNS_DEEPLINK_UNIVERSAL.replace('{campaign_id}', String(data.campaignId)),
            data.deeplink
        ).then((response: AxiosResponse) => {
            //reload deeplink
            dispatch("campaigns/getDeeplink", {campaignId: data.campaignId}, {root: true})
        }).catch((reason) => {
            if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                commit('info/error', reason.description, {root: true})
            } else {
                commit('info/error', reason, {root: true})
            }
        })
    },

    delete({commit, dispatch}, campaignId: number) {
        api.delete(ROUTES.CAMPAIGNS_DEEPLINK_UNIVERSAL.replace('{campaign_id}', String(campaignId)))
            .then((response: AxiosResponse) => {
                // commit('info/success', "Deeplink erfolgreich gelöscht", {root: true});
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },

    /**
     * ============================================================================
     * ===========================DEEPLINK QUICKTIPP===============================
     * ============================================================================
     */

    createInnerQuicktipp({commit, dispatch}, data: { campaignId: number; quicktippData: QuicktippModel }) {
        api.post(ROUTES.CAMPAIGNS_DEEPLINK_QUICKTIPP_UNIVERSAL.replace('{campaign_id}', String(data.campaignId)), data.quicktippData)
            .then((response: AxiosResponse) => {
                //reload deeplink
                dispatch("get", {campaignId: data.campaignId})
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },

    updateInnerQuicktipp({commit, dispatch}, data: { campaignId: number; quicktippData: QuicktippModel }) {
        api.put(ROUTES.CAMPAIGNS_DEEPLINK_QUICKTIPP_UNIVERSAL.replace('{campaign_id}', String(data.campaignId)))
            .then((response: AxiosResponse) => {
                //reload deeplink
                dispatch("get", {campaignId: data.campaignId})
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },

    deleteInnerQuicktipp({commit, dispatch}, campaignId: number) {
        api.delete(ROUTES.CAMPAIGNS_DEEPLINK_QUICKTIPP_UNIVERSAL.replace('{campaign_id}', String(campaignId)))
            .then((response: AxiosResponse) => {
                //reload deeplink
                dispatch("get", {campaignId: campaignId})
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    }
};

// mutations
const mutations: MutationTree<DeeplinkState> = {
    setDeeplink(state, deeplink: DeeplinkModel) {
        state.deeplink = deeplink;
    },
    resetState(state) {
        Object.assign(state, getDefaultState())
    }
};

export const deeplinks: Module<DeeplinkState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations
};



































import {NAMES} from "@/constants/names";
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  name: 'home-drawer'
})
export default class HomeDrawer extends Vue {

  @Prop({type: Array, required: true, default: []}) readonly items!: Array<string>;

  translate(pageName: string) {
    switch (pageName) {
      case NAMES.CAMPAIGNS:
        return "Kampagnen";
      case NAMES.USERS:
        return "Nutzer";
      case NAMES.DOCUMENTS:
        return "Dokumente";
      case NAMES.TICKETS:
        return "Lose";
      case NAMES.QUICKTIPPS:
        return "Quicktipps";
      case NAMES.MAINTENANCE:
        return "Wartungsfenster";
      case NAMES.REPORTS:
        return "Absturzberichte";
      case NAMES.APPVERSIONS:
        return "Appversion";
      case NAMES.SWITCHDATES:
        return "Umstellungsdaten";
      case NAMES.PUSH:
        return "Pushnachrichten";
    }
  }
}

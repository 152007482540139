import {ActionTree, Module, MutationTree} from 'vuex'
import api from '@/api/base'
import {AxiosResponse} from "axios";
import {RootState} from "@/stores/types";
import {DocumentModel, DocumentState} from "@/stores/documents/types";
import {ROUTES} from "@/constants/routes";

const getDefaultState = () => {
    return {
        documents: [],
        docDialogState: {
            isNew: false,
            document: null,
            visible: false
        }
    }
};

const state = getDefaultState();

const actions: ActionTree<DocumentState, RootState> = {
    getAll({commit}) {
        api.get(ROUTES.DOCUMENTS_GET_ALL)
            .then((response: AxiosResponse) => {
                commit('setDocuments', response)
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    upload({commit}, data: any) {
        return new Promise((resolve, reject) => {
            api.post(ROUTES.DOCUMENTS_UPLOAD, data).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err)
            })
        })
    },
    create({commit, dispatch}, data: DocumentModel) {
        api.post(ROUTES.DOCUMENTS_CREATE_NEW, data)
            .then((response: AxiosResponse) => {
                commit('info/success', "Dokument erfolgreich angelegt", {root: true});

                //Remove DocDialog
                commit('dismissDocumentDialog', state);

                //reload all documents
                dispatch("getAll")
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    update({commit, dispatch}, data) {
        api.put(ROUTES.DOCUMENT_UPDATE_EXISTING, data)
            .then((response: AxiosResponse) => {
                commit('info/success', "Dokument erfolgreich bearbeitet", {root: true});

                //Remove DocDialog
                commit('dismissDocumentDialog', state);

                //reload all documents
                dispatch("getAll")
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    delete({commit, dispatch}, {documentId}: { documentId: string }) {
        api.delete(ROUTES.DOCUMENT_DELETE_EXISTING.replace('{document_id}', documentId))
            .then((response: AxiosResponse) => {
                commit('info/success', "Dokument erfolgreich gelöscht", {root: true});

                //@TODO
                //Delete Image aswell

                //reload all documents
                dispatch("getAll")
            })
            .catch((reason) => {
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    }
};

// mutations
const mutations: MutationTree<DocumentState> = {
    showDocumentDialog(state, data: { document: DocumentModel | null; isNew: boolean }) {
        state.docDialogState.document = data.document;
        state.docDialogState.isNew = data.isNew;
        state.docDialogState.visible = true
    },
    dismissDocumentDialog(state) {
        state.docDialogState.visible = false;
        state.docDialogState.document = null;
        state.docDialogState.isNew = false
    },
    setDocuments(state, documents: Array<DocumentModel>) {
        state.documents = documents
    },
};

export const documents: Module<DocumentState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations
};

import {GAMETYPES} from "@/enum/GameTypes";

export const EXTRAGAMES = {
    SUPER6: {displayTitle: GAMETYPES.SUPER6.displayTitle, value: [GAMETYPES.SUPER6.displayTitle]},
    SPIEL77: {displayTitle: GAMETYPES.SPIEL77.displayTitle, value: [GAMETYPES.SPIEL77.displayTitle]},
    BOTH: {
        displayTitle: `${GAMETYPES.SUPER6.displayTitle} & ${GAMETYPES.SPIEL77.displayTitle}`,
        value: [GAMETYPES.SUPER6.displayTitle, GAMETYPES.SPIEL77.displayTitle]
    },
    PLUS5: {displayTitle: GAMETYPES.PLUS5.displayTitle, value: [GAMETYPES.PLUS5.displayTitle]},
};






















































import Vue from "vue";
import {Component} from 'vue-property-decorator';
import {namespace} from 'vuex-class'
import DocumentDialog from "@/components/dialogs/document/DocumentDialog.vue";
import HomeAppBar from "@/components/AppBar.vue";
import DocListRow from '@/components/DocListRow.vue';
import {DocumentDialogModel, DocumentModel} from "@/stores/documents/types";

const documentsModule = namespace('documents');

@Component({
  components: {
    HomeAppBar,
    DocumentDialog,
    DocListRow
  },
})
export default class Documents extends Vue {
  @documentsModule.Action('getAll') fetchDocuments!: Function;
  @documentsModule.State('documents') documents!: Array<DocumentModel>;
  @documentsModule.State('docDialogState') DocumentDialog!: DocumentDialogModel;
  @documentsModule.Mutation('showDocumentDialog') showDialog!: Function;
  //@documentsModule.Action('delete') deleteDocument!: Function

  search = '';
  footerProps = {
    'items-per-page-options': [5, 10, 20, -1]
  };

  get headers() {
    //TODO some criteria from backend on which org we are
    return [
      //Adjusted for BB, how to incorporate multiple images, quicktipps in overall display?
      {text: '', align: 'left', width: 64, value: null, sortable: false},
      {text: 'Kategorie', align: 'left', value: 'category', sortable: true},
      {text: 'Key', align: 'left', value: 'key', sortable: true},
      {text: 'Titel', align: 'left', value: 'title', sortable: true},
      {text: 'Verfügbar ab', align: 'left', value: 'publish_at', sortable: true},
      {text: 'Verfügbar bis', align: 'left', value: 'publish_until', sortable: true},
      {text: 'Optionen', align: 'left', value: 'option', sortable: false}
    ];
  }

  mounted() {
    this.fetchDocuments()
  }

  onNewDocument() {
    this.showDialog({campaign: null, isNew: true})
  }
}

export const GAMETYPES = {
    LOTTO: {displayTitle: "LOTTO 6aus49", altServerValue: "LOTTO"},
    LOTTO_SYSTEM: {displayTitle: "LOTTO 6aus49 System", altServerValue: "LOTTO"},
    EUROJACKPOT: {displayTitle: "Eurojackpot", altServerValue: "Eurojackpot"},
    EUROJACKPOT_SYSTEM: {displayTitle: "Eurojackpot System", altServerValue: "Eurojackpot"},
    GLUECKSSPIRALE: {displayTitle: "GlücksSpirale", altServerValue: "GlücksSpirale"},
    GLUECKSSPIRALE_JAHRESLOS: {displayTitle: "GlücksSpirale_Jahreslos", altServerValue: "GlücksSpirale"},
    KENO: {displayTitle: "KENO", altServerValue: "KENO"},
    TOTO_AW: {displayTitle: "TOTO Auswahlwette", altServerValue: ""},   //possible addition of fallback name in future as some Orgs map it to 'TOTO Auswahltipp'
    TOTO_EW: {displayTitle: "TOTO 13er Wette", altServerValue: ""},     //possible addition of fallback name in future as some  Orgs map it to 'TOTO 13er-Tipp'
    SUPER6: {displayTitle: "SUPER 6", altServerValue: ""},
    SPIEL77: {displayTitle: "Spiel 77", altServerValue: ""},
    SIEGERCHANCE: {displayTitle: "Die Sieger-Chance", altServerValue: ""},
    PLUS5: {displayTitle: "plus 5", altServerValue: ""},
    BINGO: {displayTitle: "BINGO", altServerValue: "BINGO"},            //of our Organizations only ST (Sachsen-Anhalt) serves BINGO! currently
    BAYERNMILLIONEN: {displayTitle: "BayernMILLIONEN", altServerValue: ""},
};

export interface GameType {
    displayTitle: string;

    //Used for Push Suite
    altServerValue: string;
}

export function convertGameTypeToString(name: GameType | null): string | null {
    switch (name) {
        case GAMETYPES.LOTTO:
            return GAMETYPES.LOTTO.displayTitle;
        case GAMETYPES.LOTTO_SYSTEM:
            return GAMETYPES.LOTTO_SYSTEM.displayTitle;
        case GAMETYPES.EUROJACKPOT:
            return GAMETYPES.EUROJACKPOT.displayTitle;
        case GAMETYPES.EUROJACKPOT_SYSTEM:
            return GAMETYPES.EUROJACKPOT_SYSTEM.displayTitle;
        case GAMETYPES.GLUECKSSPIRALE:
            return GAMETYPES.GLUECKSSPIRALE.displayTitle;
        case GAMETYPES.GLUECKSSPIRALE_JAHRESLOS:
            return GAMETYPES.GLUECKSSPIRALE_JAHRESLOS.displayTitle;
        case GAMETYPES.KENO:
            return GAMETYPES.KENO.displayTitle;
        case GAMETYPES.TOTO_AW:
            return GAMETYPES.TOTO_AW.displayTitle;
        case GAMETYPES.TOTO_EW:
            return GAMETYPES.TOTO_EW.displayTitle;
        case GAMETYPES.SPIEL77:
            return GAMETYPES.SPIEL77.displayTitle;
        case GAMETYPES.SUPER6:
            return GAMETYPES.SUPER6.displayTitle;
        case GAMETYPES.SIEGERCHANCE:
            return GAMETYPES.SIEGERCHANCE.displayTitle;
        case GAMETYPES.PLUS5:
            return GAMETYPES.PLUS5.displayTitle;
        case GAMETYPES.BINGO:
            return GAMETYPES.BINGO.displayTitle;
        case GAMETYPES.BAYERNMILLIONEN:
            return GAMETYPES.BAYERNMILLIONEN.displayTitle;
        default:
            return null
    }
}

export function convertGameTypeFromString(name: string | null | undefined): GameType | null {
    switch (name) {
        case GAMETYPES.LOTTO.displayTitle:
            return GAMETYPES.LOTTO;
        case GAMETYPES.LOTTO_SYSTEM.displayTitle:
            return GAMETYPES.LOTTO_SYSTEM;
        case GAMETYPES.EUROJACKPOT.displayTitle:
            return GAMETYPES.EUROJACKPOT;
        case GAMETYPES.EUROJACKPOT_SYSTEM.displayTitle:
            return GAMETYPES.EUROJACKPOT_SYSTEM;
        case GAMETYPES.GLUECKSSPIRALE.displayTitle:
            return GAMETYPES.GLUECKSSPIRALE;
        case GAMETYPES.GLUECKSSPIRALE_JAHRESLOS.displayTitle:
            return GAMETYPES.GLUECKSSPIRALE_JAHRESLOS;
        case GAMETYPES.KENO.displayTitle:
            return GAMETYPES.KENO;
        case GAMETYPES.TOTO_AW.displayTitle:
            return GAMETYPES.TOTO_AW;
        case GAMETYPES.TOTO_EW.displayTitle:
            return GAMETYPES.TOTO_EW;
        case GAMETYPES.SPIEL77.displayTitle:
            return GAMETYPES.SPIEL77;
        case GAMETYPES.SUPER6.displayTitle:
            return GAMETYPES.SUPER6;
        case GAMETYPES.SIEGERCHANCE.displayTitle:
            return GAMETYPES.SIEGERCHANCE;
        case GAMETYPES.PLUS5.displayTitle:
            return GAMETYPES.PLUS5;
        case GAMETYPES.BINGO.displayTitle:
            return GAMETYPES.BINGO;
        case GAMETYPES.BAYERNMILLIONEN.displayTitle:
            return GAMETYPES.BAYERNMILLIONEN;
        default:
            return null
    }
}








































































































import {namespace} from "vuex-class";
import {Component, Watch} from "vue-property-decorator";
import Vue from "vue";
import {UserDialogState} from "@/stores/users/types";
import {Organization, ORGANIZATIONS} from "@/enum/Organizations";
import {getRoleForName, ROLES} from "@/enum/Roles";
import {User} from "@/stores/auth/types";

const authModule = namespace('auth');
const usersModule = namespace('users');

@Component({
  name: 'UserDialog.vue'
})
export default class UserDialog extends Vue {
  @authModule.Getter('gesellschaft') gesellschaft!: Organization;
  @authModule.Getter('user') user!: User;

  @usersModule.Action("addNewUser") onSave!: Function;
  @usersModule.Action("updateExistedUser") updateUser!: Function;
  @usersModule.State('userDialogState') state!: UserDialogState;
  @usersModule.Mutation('dismissUserDetails') closeDialog!: Function;

  email = '';
  password = '';
  rePassword = '';
  selectedRoleName: string | null = null;
  showPass = false;
  dialogTitle = 'Neuer Nutzer';
  canSave = true;
  passwordsMatch = false;
  emailRules = {
    required: (v: string) => !!v || 'Bitte geben Sie eine E-Mail ein',
    validity: (v: string) =>
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Eingabe entspricht keinem Email Format'
  };
  rolesRules = {
    required: (v: string) => !!v || 'Bitte wählen Sie eine Rolle aus'
  };
  passwordRules = {
    notEmpty: (value: string) => !!value || 'Dieses Feld ist erforderlich',
    // match: (value: string) => this.passwordsMatch || 'Dieses Feld ist erforderlich'
  };
  passwordHint = "Das Passwort soll mindestens aus 8 Zeichen bestehen";

  get PasswordsEmpty() {
    return !this.password && !this.rePassword
  }

  get roles(): string[] {
    const roles = [];
    //Further expansion in future
    switch (this.gesellschaft) {
      case ORGANIZATIONS.BY:
        roles.push(ROLES.ADMIN.name);
        roles.push(ROLES.QA.name);
        roles.push(ROLES.MARKETING.name);
        roles.push(ROLES.EDITOR.name);
        break;
      default:
        roles.push(ROLES.ADMIN.name);
        roles.push(ROLES.MARKETING.name);
        break;
    }
    return roles
  }

  PasswordRules(v: string) {
    if (!this.PasswordsEmpty && !this.passwordsMatch) {
      return !!v || "Dieses Feld ist erforderlich"
    }
  }

  save() {
    if ((this.$refs.form as HTMLFormElement).validate()) {
      if (this.state.isNew) {
        const data = {
          email: this.email,
          role: getRoleForName(this.selectedRoleName)?.identifier,
          password: this.password
        };
        this.onSave(data)
      } else {
        const data = {
          user_id: this.state.user?.user_id,
          email: this.email,
          role: getRoleForName(this.selectedRoleName)?.identifier,
          password: this.password
        };
        if (this.password.length === 0) {
          //remove password if not set
          delete data.password
        }
        this.updateUser(data)
      }
    }
  }

  close() {
    this.closeDialog()
  }

  // Don't allow changing your own Role
  restrictRoleChange(): boolean {
    return this.state.user?.user_id == this.user.user_id;
  }

  @Watch('state.visible')
  onShowDialog() {
    if (!this.state.isNew) {
      this.dialogTitle = 'Nutzer bearbeiten';
      if (this.state.user?.email) {
        this.email = this.state.user.email
      }

      this.password = '';
      this.rePassword = '';

      if (this.state.user?.role) {
        this.selectedRoleName = this.state.user.role.name
      }
    } else {
      this.dialogTitle = 'Neuer Nutzer';
      this.email = '';
      this.selectedRoleName = null;
      this.password = '';
      this.rePassword = ''
    }
  }

  @Watch('password')
  checkPassword(newVal: string) {
    this.passwordsMatch = newVal === this.rePassword
  }

  @Watch('rePassword')
  checkRePassword(newVal: string) {
    this.passwordsMatch = newVal === this.password
  }
}

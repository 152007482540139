import {ActionTree, GetterTree, Module, MutationTree} from 'vuex'
import api from '@/api/base'
import {AxiosResponse} from "axios";
import {RootState} from "@/stores/types";
import {AuthModel, AuthState, ImageDimension, OrgConfig, User} from "@/stores/auth/types";
import {ROUTES} from "@/constants/routes";
import {getOrgForCode, Organization} from "@/enum/Organizations";
import {VALUES} from "@/constants/values";

const getDefaultState = () => {
    return {
        auth_token: null,
        config: {
            image_dimensions: [],
            url_deeplink: false,
            content_deeplink: false,
            quicktipp_deeplink: false,
            url_extra_options: false,
            supports_tablets_images: false,
            has_forced_update: false,
            supports_prio: false,
            has_maintenance: false,
            has_user_management: false,
            has_campaign_management: false,
            has_editorial_push: false,
            has_simple_quicktipps: false,
            has_lot_management: false,
            has_pdf_management: false,
            has_report_management: false,
            allow_ej_switch: false,
            app_version: false,
            app_version_with_formatted_description: false,
            product_id: "service",
            disable_cms_1_0: false,
            has_page_management: false,
            adimages_enabled: false
        },
        gesellschaft: {
            ges_nr: -1,
            id: -1,
            name: ''
        },
        user: {
            email: '',
            user_id: -1,
            role: {
                identifier: '',
                name: '',
                'role_id': -1,
            }
        }
    }
};

const state = getDefaultState();

const actions: ActionTree<AuthState, RootState> = {
    login({commit}, data: { email: string; password: string }) {
        api.post(ROUTES.LOGIN, data)
            .then((response: AxiosResponse) => {
                commit('setAuthentication', response)
            })
            .catch((reason) => {
                //  commit in other state
                //  commit('<moduleName>/<actionName>', <data defined>, {root: true})
                //  root: true here means operating with the global namespace
                if (Object.prototype.hasOwnProperty.call(reason, 'code')) {
                    commit('info/error', reason.description, {root: true})
                } else {
                    commit('info/error', reason, {root: true})
                }
            })
    },
    logout({commit}) {
        api.post(ROUTES.LOGOUT)
            .then((response: AxiosResponse) => {
                commit('resetState')
            })
            .catch((reason) => {
                //no big deal if this failed for some reason
                commit('resetState')
            })
    },
};

// mutations
const mutations: MutationTree<AuthState> = {
    setAuthentication(state, authData: AuthModel) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        state.auth_token = authData.auth_token;
        state.config = authData.config;
        state.gesellschaft = authData.gesellschaft;
        state.user = authData.user;
        //In the future possibly via js-cookie or something
        localStorage.setItem(VALUES.PERSISTED_AUTH, JSON.stringify(authData))
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
        localStorage.removeItem(VALUES.PERSISTED_AUTH)
    }
};

//Auth Token is watched on Top (App) Level
//Auth token is injected in requests in api/base.ts
export const getters: GetterTree<AuthState, RootState> = {
    config(state): OrgConfig {
        return state.config
    },
    imageDimensions(state): Array<ImageDimension> | null {
        return state.config.image_dimensions
    },
    gesellschaft(state): Organization {
        return getOrgForCode(state.gesellschaft.ges_nr, state.config.product_id)
    },
    user(state): User {
        return state.user
    }
};

export const auth: Module<AuthState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

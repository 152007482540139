









































































































































































































import Vue from "vue";
import {Component, Watch} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import {CampaignDialogModel} from "@/stores/campaigns/types";
import {convertGameTypeFromString, GAMETYPES} from "@/enum/GameTypes";
import {DRAWDAYS} from "@/enum/DrawDays";
import {EXTRAGAMES} from "@/enum/ExtraGames";
import {productForIdentifier} from "@/enum/Products";
import {OrgConfig} from "@/stores/auth/types";
import {Organization, ORGANIZATIONS} from "@/enum/Organizations";
import {QuicktippModel, QuicktippsDialogModel} from "@/stores/quicktipps/types";
import {QuicktippType} from "@/enum/QuicktippTypes";
import {VALUES} from "@/constants/values";

const authModule = namespace('auth');
const quicktippsModule = namespace('quicktipps');
const deeplinkModule = namespace('deeplinks');
const infoModule = namespace('info');

@Component({
  name: 'QuicktippsDialog',
  components: {},
})

export default class QuicktippsDialog extends Vue {
  @authModule.Getter('config') config!: OrgConfig;
  @authModule.Getter('gesellschaft') organization!: Organization;

  @quicktippsModule.State('dialogState') state!: QuicktippsDialogModel;
  @quicktippsModule.State('quicktipps') storedQuicktipps!: Array<QuicktippModel>;
  @quicktippsModule.Mutation('dismissQuicktippsDialog') dismiss!: Function;

  @quicktippsModule.Action('create') createQuicktippSimple!: Function;
  @quicktippsModule.Action('update') updateQuicktippSimple!: Function;

  @quicktippsModule.Action('createOnCampaign') createQuicktippsCampaign!: Function;
  @quicktippsModule.Action('updateOnCampaign') updateQuicktippsCampaign!: Function;
  @deeplinkModule.Action('createInnerQuicktipp') createDeeplinkQuicktipp!: Function;
  @deeplinkModule.Action('updateInnerQuicktipp') updateDeeplinkQuicktipp!: Function;

  @infoModule.Action('error') showError!: Function;

  extraGames: string | null = null;

  //GSP specific
  shares: number | null = null;

  //KENO specific
  type: number | null = null;
  bet: number | null = null;

  quicktippsRowsNumber = 1;
  duration = 0;
  day = '';
  dialogTitle = "Quicktipp anlegen";
  canSave = true;
  quicktippType = '';

  typeGS = GAMETYPES.GLUECKSSPIRALE.displayTitle;
  typeKENO = GAMETYPES.KENO.displayTitle;

  rules = {
    notEmpty: (value: string) => !!value || "Dieses Feld ist erforderlich",
    minimum1: (value: string) => Number(value) >= 1 || "Dieses Feld ist erforderlich",
    minimum0: (value: string) => Number(value) >= 0 || "Dieses Feld ist erforderlich",
    isNumber: (value: string) => this.isNumber(value) || "Dieses Feld darf nur aus Ziffern bestehen"
  };

  get QuicktippsTypes() {
    const types = [];


    //shared ones between all regardless of product
    types.push(GAMETYPES.LOTTO.displayTitle);
    if (this.state.contextIdentifier == QuicktippType.SIMPLE.identifier && this.isBrandenburg()) {
      types.push(GAMETYPES.LOTTO_SYSTEM.displayTitle);
    }
    types.push(GAMETYPES.EUROJACKPOT.displayTitle);
    types.push(GAMETYPES.GLUECKSSPIRALE.displayTitle);

    if (this.organization !== ORGANIZATIONS.ST) {
      types.push(GAMETYPES.KENO.displayTitle)
    }

    return types
  }

  get QTMaxFields() {
    let max = 20;
    const product = productForIdentifier(this.config.product_id);
    const gameType = convertGameTypeFromString(this.quicktippType);

    if (product && gameType) {
      max = this.organization.siteConfig.get(product)?.fields.get(gameType) ?? 20
    }

    return max
  }

  get QTExtraGames() {
    if (this.quicktippType === GAMETYPES.KENO.displayTitle) {
      return [
        EXTRAGAMES.PLUS5,
      ]
    } else {
      return [
        EXTRAGAMES.SUPER6,
        EXTRAGAMES.SPIEL77,
        EXTRAGAMES.BOTH
      ]
    }
  }

  get QTDrawDays() {
    return [
      DRAWDAYS.MONDAY,
      DRAWDAYS.TUESDAY,
      DRAWDAYS.WEDNESDAY,
      DRAWDAYS.THURSDAY,
      DRAWDAYS.FRIDAY,
      DRAWDAYS.SATURDAY,
      DRAWDAYS.SUNDAY,
      DRAWDAYS.WEDNESDAY_a_SATURDAY,
      DRAWDAYS.TUESDAY_a_FRIDAY
    ].filter(it => it.rel.includes(this.quicktippType))
  }

  get QuicktippsProp() {
    return this.state.quicktipp
  }

  get durationLabel() {
    if (this.quicktippType === GAMETYPES.KENO.displayTitle) {
      return "Laufzeit in Tagen";
    } else {
      return "Laufzeit in Wochen"
    }
  }

  get MapBonusGamesToExtraGames() {
    const match = this.QTExtraGames.filter(
        item => item.value.sort().join(',') === this.QuicktippsProp?.bonus_games?.sort()?.join(',')
    );
    if (match.length > 0) {
      return match[0].displayTitle
    }
    return null
  }

  @Watch('state.visible')
  onShowChanged(val: CampaignDialogModel) {
    if (this.state.isNew) {
      this.dialogTitle = 'Quicktipp anlegen'
    } else {
      this.dialogTitle = 'Quicktipp bearbeiten';
      this.quicktippType = this.QuicktippsProp?.game ?? '';
      this.extraGames = this.MapBonusGamesToExtraGames ?? '';
      this.duration = this.QuicktippsProp?.duration ?? 1;
      this.day = this.QuicktippsProp?.draw_day_type ?? '';
      this.quicktippsRowsNumber = this.QuicktippsProp?.quicktipp_value ?? 1
    }
  }

  onConfirm() {
    if (this.state.isNew) {
      if (this.state.contextIdentifier == QuicktippType.SIMPLE.identifier && this.storedQuicktipps.filter((quicktipp: QuicktippModel) => quicktipp.game === this.quicktippType).length >= VALUES.QUICKTIPPS_PER_LOTTERY_MAX_COUNT) {
        this.showError(`Die maximale Quicktippanzahl für "${this.quicktippType}" wurde erreicht`);
      } else {
        this.handleAction()
      }
    } else {
      this.handleAction()
    }
  }

  handleAction() {
    if (((this.$refs.qtForm as HTMLFormElement).validate())) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: { [index: string]: any } = {
        "quicktippData": {
          "type": this.state.contextIdentifier,
          "game": this.quicktippType,
          "duration": this.duration,
          "draw_day_type": this.QTDrawDays.length > 0 ? this.day : null,
          "quicktipp_value": this.quicktippsRowsNumber,
          "bonus_games": this.buildBonusGamesArray()
          // Provisional implementation, not needed (yet), if needed can comment in later
          // "shares": this.quicktippType == this.typeGS ? this.shares : null,
          // "keno_type": this.quicktippType == this.typeKENO ? this.type : null,
          // "keno_bet": this.quicktippType == this.typeKENO ? this.bet : null,
        }
      };

      if (this.state.contextIdentifier == QuicktippType.CAMPAIGN.identifier) {
        data['campaignId'] = this.state.campaignId;
      }

      if (this.state.isNew) {
        if (this.isBrandenburg()) {
          if (this.state.contextIdentifier == QuicktippType.CAMPAIGN.identifier) {
            this.createQuicktippsCampaign(data)
          } else if (this.state.contextIdentifier == QuicktippType.SIMPLE.identifier) {
            this.createQuicktippSimple(data)
          }
        } else {
          this.createDeeplinkQuicktipp(data)
        }
      } else {
        data['quicktippId'] = this.QuicktippsProp ? this.QuicktippsProp.id : null;
        if (this.isBrandenburg()) {
          if (this.state.contextIdentifier == QuicktippType.CAMPAIGN.identifier) {
            this.updateQuicktippsCampaign(data)
          } else if (this.state.contextIdentifier == QuicktippType.SIMPLE.identifier) {
            this.updateQuicktippSimple(data)
          }
        } else {
          this.updateDeeplinkQuicktipp(data)
        }
      }
    }
  }

  onCancel() {
    this.dismiss()
  }

  resetExtraGames() {
    this.extraGames = null
  }

  resetQuicktippFields() {
    this.quicktippsRowsNumber = 1
  }

  buildBonusGamesArray(): string[] | null {
    switch (this.extraGames) {
      case GAMETYPES.SUPER6.displayTitle:
        return [GAMETYPES.SUPER6.displayTitle];
      case GAMETYPES.SPIEL77.displayTitle:
        return [GAMETYPES.SPIEL77.displayTitle];
      case `${GAMETYPES.SUPER6.displayTitle} & ${GAMETYPES.SPIEL77.displayTitle}`:
        return [GAMETYPES.SUPER6.displayTitle, GAMETYPES.SPIEL77.displayTitle];
      case GAMETYPES.PLUS5.displayTitle:
        return [GAMETYPES.PLUS5.displayTitle];
      default:
        return null
    }
  }

  isNumber(value: string) {
    return !isNaN(parseFloat(value)) && !isNaN(Number(value))
  }

  isBrandenburg() {
    return this.organization === ORGANIZATIONS.BB;
  }
}

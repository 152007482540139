export const PLATFORMS = {
    ANDROID: {serverIdentifier: "android"},
    IOS: {serverIdentifier: "ios"}
};

export interface Platform {
    serverIdentifier: string;
}

export function identifyPlatform(os: string | null): Platform | null {
    switch (os) {
        case PLATFORMS.ANDROID.serverIdentifier:
            return PLATFORMS.ANDROID;
        case PLATFORMS.IOS.serverIdentifier:
            return PLATFORMS.IOS;
        default:
            return null
    }
}











































import {namespace} from "vuex-class";
import Vue from "vue";
import {Component} from "vue-property-decorator";
import QuestionStore from "@/components/dialogs/question/QuestionStore";
import {UserModel} from "@/stores/users/types";

const UserModule = namespace('users');

@Component({
  name: 'user-row',
  props: ['user']
})
export default class UserRow extends Vue {
  @UserModule.Mutation('showUserDetails') showUserDetails!: Function
  @UserModule.Action('deleteUser') deleteUser!: Function

  editClicked() {
    this.showUserDetails({user: this.$props.user, isNew: false})
  }

  deleteClicked(user: UserModel) {
    QuestionStore.poseQuestion(
        null,
        `Nutzer ${user.email} wirklich löschen? Dies kann nicht rückgängig gemacht werden`,
        ['Ja', 'Nein'],
        -1
    ).then((answerIndex) => {
      if (answerIndex === 0) {
        this.deleteUser({userId: user.user_id})
      }
    })
  }
}






















































import Vue from "vue";
import {Component} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import HomeAppBar from "@/components/AppBar.vue";
import {TicketDialogModel, TicketModel} from "@/stores/tickets/types";
import TicketListRow from "@/components/TicketListRow.vue";
import TicketDialog from "@/components/dialogs/ticket/TicketDialog.vue";

const ticketModule = namespace('tickets');

@Component({
  components: {
    TicketDialog,
    TicketListRow,
    HomeAppBar
  },
})
export default class Tickets extends Vue {
  @ticketModule.State('tickets') tickets!: Array<TicketModel>;
  @ticketModule.Action('getAll') fetchTickets!: Function;
  @ticketModule.State('ticketDialogState') DocumentDialog!: TicketDialogModel;
  @ticketModule.Mutation('showTicketDialog') showDialog!: Function;

  search = '';
  footerProps = {
    'items-per-page-options': [5, 10, 20, -1]
  };

  get headers() {
    //TODO some criteria from backend on which org we are
    return [
      //Adjusted for BB, how to incorporate multiple images, quicktipps in overall display?
      {text: '', align: 'left', width: 64, value: null, sortable: false},
      {text: 'Überschrift', align: 'left', value: 'headline', sortable: true},
      {text: 'Beschreibung', align: 'left', value: 'description', sortable: false},
      {text: 'Hauptgewinn in Cent', align: 'left', value: 'cent_hauptgewinn', sortable: true},
      {text: 'Preis in Cent', align: 'left', value: 'cent_price', sortable: true},
      {text: 'Chance', align: 'left', value: 'chance_hauptgewinn', sortable: true},
      {text: 'Optionen', align: 'left', value: 'option', sortable: false}
    ];
  }

  mounted() {
    this.fetchTickets()
  }

  onNewTicket() {
    this.showDialog({los: null, isNew: true})
  }

}

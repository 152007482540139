import Vue from 'vue'
import Vuex from 'vuex'
import {auth} from './auth'
import {campaigns} from './campaigns'
import {info} from "@/stores/info";
import {documents} from "@/stores/documents";
import {tickets} from "@/stores/tickets";
import {users} from "@/stores/users"
import {quicktipps} from "@/stores/quicktipps";
import {deeplinks} from "@/stores/deeplinks"
import {reports} from "@/stores/reports"
import {appversions} from "@/stores/appversions";
import {switchdates} from "@/stores/switchdates";
import {push} from "@/stores/push";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        campaigns,
        info,
        documents,
        tickets,
        users,
        quicktipps,
        deeplinks,
        reports,
        appversions,
        switchdates,
        push
    }
})



















































































































































































































































































































































import Vue from "vue";
import {Component, Watch} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import {CampaignDialogModel, CampaignImage, CampaignModel} from "@/stores/campaigns/types";
import VDatetimePicker from "@/components/datetimepicker/DatetimePicker.vue";
import QuicktippsDialog from "@/components/dialogs/QuicktippsDialog.vue";
import QuestionStore from "@/components/dialogs/question/QuestionStore";
import timeUtil from "@/utils/time"
import {VALUES} from "@/constants/values";
import {productForIdentifier, PRODUCTS} from "@/enum/Products";
import {ImageDimension, OrgConfig, User} from "@/stores/auth/types";
import {Organization, ORGANIZATIONS} from "@/enum/Organizations";
import {convertGameTypeFromString, GAMETYPES} from "@/enum/GameTypes";
import {DIMENS, Dimension} from "@/enum/Dimension";
import {
  DEEPLINK,
  deeplinkcontentActionForGame,
  isQuicktippDeeplink,
  isSpielscheinDeeplink,
  isUrlDeeplink,
  maskedDeeplinkActionForAction,
  quicktippActionForGame
} from "@/enum/Deeplinks";
import {DeeplinkModel} from "@/stores/deeplinks/types";
import {QuicktippModel} from "@/stores/quicktipps/types";
import {QuicktippType} from "@/enum/QuicktippTypes";
import {ROLES} from "@/enum/Roles";
import moment from "moment";

const authModule = namespace('auth');
const campaignsModule = namespace('campaigns');
const quicktippsModule = namespace('quicktipps');
const deeplinkModule = namespace('deeplinks');
const infoModule = namespace('info');

@Component({
  name: 'CampaignDialog',
  components: {
    VDatetimePicker,
    QuicktippsDialog
  },
})
export default class CampaignDialog extends Vue {
  @authModule.Getter('config') config!: OrgConfig;
  @authModule.Getter('gesellschaft') organization!: Organization;
  @authModule.Getter('user') user!: User;
  @authModule.Getter('imageDimensions') dimens!: Array<ImageDimension> | null;

  @campaignsModule.Action('getAll') fetchCampaigns!: Function;
  @campaignsModule.State('dialogState') state!: CampaignDialogModel;

  @campaignsModule.Action('update') updateCampaign!: Function;
  @campaignsModule.Action('create') createCampaign!: Function;
  @campaignsModule.Action('upload') uploadImage!: Function;
  @campaignsModule.Action('deleteImage') deleteImage!: Function;
  @campaignsModule.Action('showInfo') showMessage!: Function;

  //special
  @infoModule.Action('setLoadingDismissalFlag') setLoadingDismissalFlag!: Function;

  @deeplinkModule.Action('create') createDeeplinkOnCampaign!: Function;
  @deeplinkModule.Action('update') updateDeeplinkOnCampaign!: Function;
  @deeplinkModule.Action('delete') deleteDeeplinkOnCampaign!: Function;
  @deeplinkModule.Action('deleteInnerQuicktipp') deleteDeeplinkQuicktippOnCampaign!: Function;

  //Brandenburg uses Array of Quicktipp Objects while other Orgs use singular deeplink object
  @campaignsModule.Action('getAllQuicktipps') fetchQuicktipps!: Function;
  @campaignsModule.Action('getDeeplink') fetchDeeplink!: Function;

  @campaignsModule.Action('showInformationDialog') showInformation!: Function;
  @campaignsModule.Mutation('adjustCampaignDialog') adjustDialog!: Function;
  @campaignsModule.Getter('dialogMessage') message!: string | null;
  @campaignsModule.Action('getAllCampaigns') getAllCampaigns!: Function;

  @campaignsModule.State('campaigns') campaigns!: Array<CampaignModel>;

  @quicktippsModule.Mutation('showQuicktippsDialog') showQuicktippsDialog!: Function;
  @quicktippsModule.Action('deleteOnCampaign') deleteQuicktippsCampaign!: Function;

  @infoModule.State('loading') loading!: boolean;

  // Typescript $refs declaration
  $refs!: {
    campaignForm: HTMLFormElement;
  };

  loadActive = false;
  validCampaignForm = false;
  filesPhone: any | null = null;
  filesTablet: any | null = null;
  datetimeStart: Date | null = null;
  datetimeEnd: Date | null = null;
  dialogTitle = 'Kampagne erstellen';
  headline = '';
  description = '';
  selectedDeeplinkTypeValue = '';
  maskedDeeplinkAction = '';
  deeplinkUrl = '';
  deeplinkButtonText = ''

  deeplinkTypeUrl = false;
  deeplinkTypeSpielschein = false;
  deeplinkTypeQuicktipp = false;
  //Validation rules handled on Backend

  headlineCharLimit = 48
  descriptionCharLimit = 60
  buttonCharLimit = 45

  imageRules = {
    required: (v: {}) => !!v || 'Bitte wählen Sie ein Bild aus'
  };

  rules = {
    notEmpty: (value: string) => !!value || 'Dieses Feld ist erforderlich',
    // https://www.regextester.com/102903
    // https? if http is ok as well
    urlValidity: (v: string) => /(https:\/\/[^\s]+)/.test(v) || 'Keine gültige URL. Format: https://<...>'
  };

  //misc
  spielscheinDeeplinkValue = DEEPLINK.SPIELSCHEIN.serverIdentifier
  urlDeeplinkValue = DEEPLINK.URL.serverIdentifier
  rpOrganizationName = ORGANIZATIONS.RP.name
  dimenIdPhone = DIMENS.CAMPAIGN.identifier
  dimenIdTablet = DIMENS.CAMPAIGN_TABLET.identifier
  hasPhoneDimens = false;
  hasTabletDimens = false;
  // Helper flag for not resetting linkType selector on initialization (first change event) of Campaign to be edited
  linkTypeInitialized = false;

  get deeplinkTypes() {
    const deeplinkArray = [];

    if (this.config.url_deeplink || this.config.content_deeplink || this.config.quicktipp_deeplink) {
      // RP has no campaigns without Deeplinks
      if (this.organization !== ORGANIZATIONS.RP) {
        deeplinkArray.push({name: 'keine zusätzliche Verlinkung', value: ''});
      }

      if (this.config.content_deeplink) {
        deeplinkArray.push({name: DEEPLINK.SPIELSCHEIN.name, value: DEEPLINK.SPIELSCHEIN.serverIdentifier});
      }
      if (this.config.quicktipp_deeplink) {
        deeplinkArray.push({name: DEEPLINK.QUICKTIPP.name, value: DEEPLINK.QUICKTIPP.serverIdentifier});
      }
      if (this.config.url_deeplink) {
        deeplinkArray.push({name: DEEPLINK.URL.name, value: DEEPLINK.URL.serverIdentifier});
      }
    }
    return deeplinkArray;
  }

  get linkTypes() {
    const options = [];

    //shared ones between all regardless of product
    options.push(GAMETYPES.LOTTO.displayTitle + " Normal");
    options.push(GAMETYPES.EUROJACKPOT.displayTitle + " Normal");
    options.push(GAMETYPES.GLUECKSSPIRALE.displayTitle);

    if (this.config.product_id == PRODUCTS.SVA.name) {
      switch (this.organization) {
        case ORGANIZATIONS.BB:
        case ORGANIZATIONS.RP:
        case ORGANIZATIONS.SL:
          options.push(GAMETYPES.KENO.displayTitle);
          break;
        case ORGANIZATIONS.SH:
        case ORGANIZATIONS.BE:
          options.push(GAMETYPES.GLUECKSSPIRALE_JAHRESLOS.displayTitle);
          options.push(GAMETYPES.KENO.displayTitle);
          break;
        case ORGANIZATIONS.ST:
          options.push(GAMETYPES.BINGO.displayTitle);
          break;
        case ORGANIZATIONS.BY:
          options.push(GAMETYPES.KENO.displayTitle);
          options.push(GAMETYPES.GLUECKSSPIRALE_JAHRESLOS.displayTitle);
          options.push(GAMETYPES.BAYERNMILLIONEN.displayTitle);
          break;
      }
    } else if (this.config.product_id == PRODUCTS.OA.name) {
      switch (this.organization) {
        case ORGANIZATIONS.ST:
          options.push(GAMETYPES.BINGO.displayTitle);
          break;
        case ORGANIZATIONS.BY:
        case ORGANIZATIONS.BE:
        case ORGANIZATIONS.RP:
        case ORGANIZATIONS.SL:
        case ORGANIZATIONS.SH:
        case ORGANIZATIONS.MV:
          options.push(GAMETYPES.KENO.displayTitle);
          options.push(GAMETYPES.GLUECKSSPIRALE_JAHRESLOS.displayTitle);
          break;
      }
    }
    return options
  }

  get CampaignSrc() {
    return this.CampaignImages ? this.getImageIfExisting(DIMENS.CAMPAIGN.identifier)?.url ?? '' : ''
  }

  get CampaignSrcTablet() {
    return this.CampaignImages ? this.getImageIfExisting(DIMENS.CAMPAIGN_TABLET.identifier)?.url ?? '' : ''
  }

  get CampaignImages() {
    return this.CampaignProp ? this.CampaignProp.images : null
  }

  get CampaignProp() {
    return this.state ? this.state.campaign : null
  }

  get QuicktippsOrDeeplinkProp() {
    if (this.isBrandenburg()) {
      return this.state.quicktipps
    } else {
      return this.state.deeplink
    }
  }

  getDimenIfExisting(identifier: string): ImageDimension | undefined {
    return this.dimens?.find(e => e.name === identifier)
  }

  getImageIfExisting(identifier: string): CampaignImage | undefined {
    return this.CampaignProp?.images?.find(e => e.type === identifier)
  }

  onCancel() {
    this.clearData();
    this.fetchCampaigns()
  }

  clearData() {
    // Delay a bit so we can properly utilize helper flags
    setTimeout(() => {
      // Important to first reset
      if (this.$refs.campaignForm) {
        this.$refs.campaignForm.reset();
      }
      this.filesPhone = null;
      this.filesTablet = null;
      this.datetimeStart = null;
      this.datetimeEnd = null;
      this.dialogTitle = 'Kampagne erstellen';
      this.headline = '';
      this.description = '';
      this.selectedDeeplinkTypeValue = '';
      this.deeplinkUrl = '';
      this.deeplinkTypeUrl = false;
      this.deeplinkTypeSpielschein = false;
      this.deeplinkTypeQuicktipp = false;

      //Helper flags
      this.linkTypeInitialized = false
    }, 100)
  }

  @Watch('state.visible')
  onShowChanged(val: CampaignDialogModel) {
    if (this.state.visible) {
      this.filesPhone = null;
      this.filesTablet = null;

      this.hasPhoneDimens = this.getDimenIfExisting(this.dimenIdPhone) != undefined;
      this.hasTabletDimens = this.getDimenIfExisting(this.dimenIdTablet) != undefined;

      if (this.state.isNew) {
        this.clearData();
        this.dialogTitle = 'Kampagne erstellen'
      } else {
        // switch for Brandenburg vs. other
        if (this.isBrandenburg()) {
          this.fetchQuicktipps({campaignId: this.CampaignProp?.id});
        } else {
          //only fetch if campaign has already an deeplink object as it is an
          //singular object while quicktipps just give an empty array if not existent
          if (this.CampaignProp?.deeplink != null) {
            this.fetchDeeplink({campaignId: this.CampaignProp?.id}).then(() => {
              if (this.QuicktippsOrDeeplinkProp != null) {
                const serverAction = (this.QuicktippsOrDeeplinkProp as DeeplinkModel).action;
                const maskedAction: string | null = maskedDeeplinkActionForAction(serverAction);

                // console.log("DEBUG1", serverAction);
                // console.log("DEBUG2", maskedAction);

                this.selectedDeeplinkTypeValue = (this.QuicktippsOrDeeplinkProp as DeeplinkModel).type;
                this.maskedDeeplinkAction = maskedAction ?? '';
                //Commented on purpose
                // this.deeplinkUrl = '';
                // Following if is the case when serverAction is a URL
                if (serverAction === maskedAction) {
                  this.deeplinkUrl = serverAction;
                }
                this.deeplinkTypeUrl = isUrlDeeplink(serverAction);
                this.deeplinkTypeSpielschein = isSpielscheinDeeplink(serverAction);
                this.deeplinkTypeQuicktipp = isQuicktippDeeplink(serverAction);
              }
            }).catch(() => {
              //
            });
          }
        }

        this.dialogTitle = 'Kampagne bearbeiten';
        this.datetimeStart = this.CampaignProp?.start ? timeUtil.parseToDate(String(this.CampaignProp?.start)) : null;
        this.datetimeEnd = this.CampaignProp?.end ? timeUtil.parseToDate(String(this.CampaignProp?.end)) : null;
        this.headline = this.CampaignProp?.headline ?? '';
        this.description = this.CampaignProp?.description ?? '';
        this.deeplinkButtonText = this.CampaignProp?.deeplink?.buttonname ?? '';
      }
    } else {
      //Reset variables when dialog dismisses
      this.clearData();
    }
  }

  @Watch('loading')
  onLoadingChanged(val: boolean, oldVal: boolean) {
    this.loadActive = val
  }

  @Watch('selectedDeeplinkTypeValue')
  onDeeplinktypeChanged(val: string) {
    // Reset deeplink action
    // CASE edit campaign with URL Deeplink and swap to Spielschein
    if (this.linkTypeInitialized) {
      // console.log("DEBUG", "reset deeplink selection")
      this.maskedDeeplinkAction = ''
    } else {
      // Due to delay implemented in clearData() take advantage
      // of watcher here when reset is executed after said delay
      // console.log("DEBUG visibility in watcher", this.state.visible)
      this.linkTypeInitialized = this.state.visible
    }

    // console.log("DEBUG", this.organization.name === this.rpOrganizationName && this.selectedDeeplinkTypeValue === this.spielscheinDeeplinkValue)
    // console.log("DEBUG", this.selectedDeeplinkTypeValue)
    // console.log("DEBUG", this.spielscheinDeeplinkValue)
    // console.log("DEBUG", this.selectedDeeplinkTypeValue === this.spielscheinDeeplinkValue)
    // console.log("DEBUG!", this.maskedDeeplinkAction)

    this.deeplinkTypeUrl = false;
    this.deeplinkTypeSpielschein = false;
    this.deeplinkTypeQuicktipp = false;

    if (val === DEEPLINK.URL.serverIdentifier)
      this.deeplinkTypeUrl = true;
    if (val === DEEPLINK.SPIELSCHEIN.serverIdentifier)
      this.deeplinkTypeSpielschein = true;
    if (val === DEEPLINK.QUICKTIPP.serverIdentifier)
      this.deeplinkTypeQuicktipp = true;
    if (val == null) {
      this.maskedDeeplinkAction = ''
    }
  }

  onConfirm() {
    const localErrorHandler = (message: string) => {
      this.showMessage({type: 'info/error', msg: message});
    }

    if (this.$refs.campaignForm.validate() && this.datetimeStart != null && this.datetimeEnd != null) {
      const formData = new FormData();
      let formDataTablet: FormData | null = null;

      //Single selection so this is safe
      if (this.filesPhone && typeof this.filesPhone === "object" && 'name' in this.filesPhone) {
        formData.append("name", this.filesPhone.name);
        formData.append("image", this.filesPhone);
        formData.append("type", DIMENS.CAMPAIGN.identifier);
      }
      if (this.config.supports_tablets_images && this.filesTablet && typeof this.filesTablet === "object" && 'name' in this.filesTablet) {
        formDataTablet = new FormData();
        formDataTablet.append("name", this.filesTablet.name);
        formDataTablet.append("image", this.filesTablet);
        formDataTablet.append("type", DIMENS.CAMPAIGN_TABLET.identifier);
      }

      if (this.state.isNew) {
        // We could take them from store but request them anew on purpose (case: cms duplicated tabs in browser)
        this.getAllCampaigns().then((campaignModels: Array<CampaignModel>) => {
          if (campaignModels.length >= VALUES.CAMPAIGNS_MAX_COUNT && this.isBrandenburg()) {
            localErrorHandler('Die maximale Kampagnenanzahl wurde erreicht.')
          } else {
            // case: Only singular campaigns allowed, different active campaigns should not overlap in their time range
            if (!this.supportsMultipleCampaigns()) {
              if (this.doesCurrentDataOverlapWithActiveCampaigns()) {
                localErrorHandler('Diese Kampagne überschneidet mit dem Zeitfenster einer bereits aktiven oder zukünftigen.')
                // Stop execution
                return
              }
            }

            this.doDimensMatchWithFileProvided(this.filesPhone, DIMENS.CAMPAIGN).then(res => {
              console.log("DEBUG", "A")
              if (this.config.supports_tablets_images) {
                this.doDimensMatchWithFileProvided(this.filesTablet, DIMENS.CAMPAIGN_TABLET).then(res => {
                  this.commenceCampaignCreation(formData, formDataTablet)
                }).catch(err => {
                  localErrorHandler('Bitte ein Bild mit angegebener Abmessung hochladen')
                })
              } else {
                this.commenceCampaignCreation(formData, formDataTablet)
              }
            }).catch(err => {
              localErrorHandler('Bitte ein Bild mit angegebener Abmessung hochladen')
            })
          }
        }).catch((err: any) => {
          console.log('err', err)
        })
      } else {
        // case: Only singular campaigns allowed, different active campaigns should not overlap in their time range
        if (!this.supportsMultipleCampaigns()) {
          if (this.doesCurrentDataOverlapWithActiveCampaigns()) {
            localErrorHandler('Diese Kampagne überschneidet mit dem Zeitfenster einer bereits aktiven oder zukünftigen.')
            // Stop execution
            return
          }
        }

        const data = {
          id: this.CampaignProp?.id,
          start: this.datetimeStart ? timeUtil.formattedDate(String(this.datetimeStart)) : null,
          end: this.datetimeEnd ? timeUtil.formattedDate(String(this.datetimeEnd)) : null,
          headline: this.headline,
          description: this.description,
          prio: 200
        };

        // special loading indicator handling
        this.setLoadingDismissalFlag();

        const doesUpdateImages = (this.filesPhone != null || (this.config.supports_tablets_images && this.filesTablet != null));
        const updatesBothImages = (this.filesPhone != null && (this.config.supports_tablets_images && this.filesTablet != null));

        // Only relevant if !updatesBothImages
        let identifierOfImageToUpdate: string | null = null;

        if (!updatesBothImages) {
          if (this.filesPhone) {
            identifierOfImageToUpdate = DIMENS.CAMPAIGN.identifier
          } else if (this.filesTablet) {
            identifierOfImageToUpdate = DIMENS.CAMPAIGN_TABLET.identifier
          }
        }

        // console.log("DEBUG one", doesUpdateImages)
        // console.log("DEBUG both", updatesBothImages)

        if (doesUpdateImages) {
          if (updatesBothImages) {
            this.handleUploadResponse(this.CampaignProp!, formData, DIMENS.CAMPAIGN.identifier).then(() => {
              if (this.config.supports_tablets_images && this.filesTablet) {
                this.handleUploadResponse(this.CampaignProp!, formDataTablet, DIMENS.CAMPAIGN_TABLET.identifier).then(() => {
                  this.finishCampaignUpdate(data)
                })
              } else {
                this.finishCampaignUpdate(data)
              }
            })
          }
          // Only update one image
          else {
            let dataOfImageToUpdate: FormData | null = null;
            if (identifierOfImageToUpdate == DIMENS.CAMPAIGN.identifier) {
              dataOfImageToUpdate = formData
            } else if (identifierOfImageToUpdate == DIMENS.CAMPAIGN_TABLET.identifier) {
              dataOfImageToUpdate = formDataTablet
            }
            if (dataOfImageToUpdate && identifierOfImageToUpdate) {
              this.handleUploadResponse(this.CampaignProp!, dataOfImageToUpdate, identifierOfImageToUpdate).then(() => {
                this.finishCampaignUpdate(data)
              })
            }
          }
        } else {
          this.finishCampaignUpdate(data)
        }
      }
    } else {
      localErrorHandler('Eingaben sind nicht vollständig oder fehlerhaft.');
    }
  }

  commenceCampaignCreation(formDataPhone: FormData, formDataTablet: FormData | null) {
    const data = {
      start: this.datetimeStart ? timeUtil.formattedDate(String(this.datetimeStart)) : null,
      end: this.datetimeEnd ? timeUtil.formattedDate(String(this.datetimeEnd)) : null,
      headline: this.headline,
      description: this.description,
      prio: 300,
    };

    // special loading indicator handling
    this.setLoadingDismissalFlag();

    this.createCampaign(data).then((campRes: CampaignModel) => {
      this.adjustDialog({campaign: campRes, isNew: false});

      if (!this.isBrandenburg()) {
        this.handleDeeplinkCreation(campRes.id)
      }

      this.handleUploadResponse(campRes, formDataPhone, DIMENS.CAMPAIGN.identifier).then(() => {
        if (this.config.supports_tablets_images && formDataTablet) {
          this.handleUploadResponse(campRes, formDataTablet, DIMENS.CAMPAIGN_TABLET.identifier).then(() => {
            this.finishCampaignDialog()
          })
        } else {
          this.finishCampaignDialog()
        }
      })
    })
  }

  finishCampaignDialog() {
    this.fetchCampaigns();
    // TODO Dismiss loading
    this.showMessage({type: 'info/success', msg: 'Kampagne erfolgreich angelegt'});
  }

  // expected data here -> CampaignModel
  finishCampaignUpdate(data: any) {
    this.updateDeeplink(this.CampaignProp?.id);
    this.updateCampaign(data).then(() => {
      this.showMessage({type: 'info/success', msg: 'Kampagne erfolgreich editiert'});
    })
  }

  updateDeeplink(id: number | null | undefined) {
    if (!this.isBrandenburg()) {
      if (this.selectedDeeplinkTypeValue == null || this.selectedDeeplinkTypeValue == '') {
        //check if deeplink exists and if so delete it
        if (this.CampaignProp?.deeplink != null && this.CampaignProp.id != null) {
          this.deleteDeeplinkOnCampaign(this.CampaignProp.id)
        }
      } else {
        //It could be that initially no deeplink was on the Object therefore check if deeplink exists, if not create it
        if (this.CampaignProp?.deeplink == null) {
          this.handleDeeplinkCreation(id)
        } else {
          this.handleDeeplinkUpdate(id)
        }
      }
      //If Brandenburg this function doesn't even get called in the first place
    }
  }

  handleDeeplinkCreation(id: number | null | undefined): Promise<boolean> {
    return new Promise((resolve, reject) => {
      //This is first level check for the selector (DeeplinkCategory) if selection qualifies for creation of an deeplink
      if (this.selectedDeeplinkTypeValue != null && this.selectedDeeplinkTypeValue !== '') {
        const adjustedLinkType = this.maskedDeeplinkAction
            .replace(" Normal", "")
            .replace(" System", "");

        //LinkType is a .displayName of a GameTypes enum entry..see get linkTypes() above
        const gameType = convertGameTypeFromString(adjustedLinkType);
        let action: string | null;

        //In the following lines is second level check (DeeplinkValue) if a selection is made or
        //an input given in actual value element and therefor qualifies for creation
        let qualifiesForCreation = false;

        if (this.selectedDeeplinkTypeValue == DEEPLINK.SPIELSCHEIN.serverIdentifier) {
          action = deeplinkcontentActionForGame(gameType);
          qualifiesForCreation = action != null;
        } else if (this.selectedDeeplinkTypeValue == DEEPLINK.QUICKTIPP.serverIdentifier) {
          action = quicktippActionForGame(gameType);
          qualifiesForCreation = action != null;
        }
        //CASE: URL
        else {
          //action = value (v-model) of url textfield
          action = this.deeplinkUrl;
          qualifiesForCreation = action != null && action.length > 0;
        }

        // console.log("DEBUG", "Qualifies for creation " + qualifiesForCreation);

        if (qualifiesForCreation) {
          let deeplinkData: {};
          if (this.supportsMultipleCampaigns()) {
            deeplinkData = {
              "campaignId": id,
              "deeplink": {
                "type": this.selectedDeeplinkTypeValue,
                "action": action,
                "game": adjustedLinkType,
                'headline': this.headline,
                "subline": this.description,
                "buttonname": this.deeplinkButtonText,
              }
            }
          } else {
            deeplinkData = {
              "campaignId": id,
              "deeplink": {
                "type": this.selectedDeeplinkTypeValue,
                "action": action,
                "game": adjustedLinkType,
              }
            }
          }
          this.createDeeplinkOnCampaign(deeplinkData).then((response: DeeplinkModel) => {
            resolve(true)
          }).catch((err: any) => {
            reject(false)
          })
        }
      } else {
        reject(false)
      }
    })
  }

  handleDeeplinkUpdate(id: number | null | undefined) {
    const adjustedLinkType = this.maskedDeeplinkAction
        .replace(" Normal", "")
        .replace(" System", "");

    const gameType = convertGameTypeFromString(adjustedLinkType);
    let action: string | null;

    if (this.selectedDeeplinkTypeValue == DEEPLINK.SPIELSCHEIN.serverIdentifier) {
      action = deeplinkcontentActionForGame(gameType);
    } else if (this.selectedDeeplinkTypeValue == DEEPLINK.QUICKTIPP.serverIdentifier) {
      action = quicktippActionForGame(gameType);
    }
    //CASE: URL
    else {
      //action = value (v-model) of url textfield
      action = this.deeplinkUrl
    }

    let deeplinkData: {};
    if (this.supportsMultipleCampaigns()) {
      deeplinkData = {
        "campaignId": id,
        "deeplink": {
          "type": this.selectedDeeplinkTypeValue,
          "action": action,
          "game": adjustedLinkType,
          'headline': this.headline,
          "subline": this.description,
          "buttonname": this.deeplinkButtonText,
        }
      }
    } else {
      deeplinkData = {
        "campaignId": id,
        "deeplink": {
          "type": this.selectedDeeplinkTypeValue,
          "action": action,
          "game": adjustedLinkType,
        }
      }
    }
    this.updateDeeplinkOnCampaign(deeplinkData)
  }

  handleUploadResponse(campRes: CampaignModel, formData: any, oldImageIdentifierToDelete: string) {
    return new Promise((resolve) => {
      const campaignImages = this.CampaignImages
      this.uploadImage({campaign_id: campRes.id, image: formData}).then(async () => {
        if (campaignImages) {
          await this.deleteCampaignImages(campRes.id ?? -1, campaignImages, oldImageIdentifierToDelete)
        }
        // The request is happening so fast, that the fetch campaigns request is still getting deleted images
        // let's add some delay here
        setTimeout(() => resolve(), 1000)
      })
    })
  }

  deleteCampaignImages(campaign_id: number, campaignImages: Array<CampaignImage>, imageTypeToDelete: string) {
    return new Promise((resolve) => {
      campaignImages.forEach((image: CampaignImage) => {
        if (image.type === imageTypeToDelete) {
          const data = {
            campaign_id: campaign_id,
            'image_id': image.id
          };
          this.deleteImage(data)
        }
      });
      resolve()
    })
  }

  editQuicktipp(quicktipp: QuicktippModel) {
    this.showQuicktippsDialog({
      quicktipp: quicktipp,
      isNew: false,
      context: QuicktippType.CAMPAIGN.identifier,
      campaignId: this.CampaignProp ? this.CampaignProp.id : null
    })
  }

  deleteQuicktipp(quicktipp: QuicktippModel) {
    QuestionStore.poseQuestion(
        null,
        `Diese Quicktipps wirklich löschen? Dies kann nicht rückgängig gemacht werden`,
        ['Ja', 'Nein'],
        -1
    ).then((answerIndex: number) => {
      if (answerIndex === 0) {
        if (this.isBrandenburg()) {
          this.deleteQuicktippsCampaign({
            campaignId: this.CampaignProp ? this.CampaignProp.id : null,
            quicktippId: quicktipp.id
          })
        } else {
          this.deleteDeeplinkQuicktippOnCampaign(this.CampaignProp ? this.CampaignProp.id : null)
        }
      }
    })
  }

  canCreateQuicktipps(): boolean {
    if (this.isBrandenburg()) {
      return ((this.QuicktippsOrDeeplinkProp as QuicktippModel[])?.length ?? 0) < 2;
    } else {
      return (this.QuicktippsOrDeeplinkProp as DeeplinkModel)?.quicktipp != null;
    }
  }

  showQTDialog() {
    if (this.CampaignProp) {
      this.showQuicktippsDialog({
        quicktipps: null,
        isNew: true,
        context: QuicktippType.CAMPAIGN.identifier,
        campaignId: this.CampaignProp ? this.CampaignProp.id : null
      })
    } else {
      this.showInformation({msg: 'Bitte speichern Sie die Kampagne-Daten, bevor Sie mit den Quicktipps fortfahren.'});
    }
  }

  openImage(url: string) {
    window.open(url)
  }

  datePickerLabel(isEndtime: boolean): string {
    if (this.isBrandenburg()) {
      if (isEndtime) {
        return "Endzeit"
      } else {
        return "Startzeit"
      }
    } else {
      if (isEndtime) {
        return "Endzeit*"
      } else {
        return "Startzeit*"
      }
    }
  }

  deeplinkLabel(): string {
    let label = "Deeplink"
    if (this.organization == ORGANIZATIONS.RP) {
      label = "Deeplink*"
    }
    return label
  }

  deeplinkSpielscheinLabel(): string {
    let label = "Linktyp"
    if (this.organization == ORGANIZATIONS.RP) {
      label = "Linktyp*"
    }
    return label
  }

  deeplinkSpielscheinHint(): string {
    let hint = ""
    if (this.organization !== ORGANIZATIONS.RP) {
      hint = "Bei leerer Auswahl wird Spielschein Deeplink nicht angewandt"
    }
    return hint
  }

  actionButtonLabel(): string {
    // BY has this optional but currently no plan to integrate in this project
    return "Text des Aktionsbuttons*"
  }

  isOrgUnderBerlin(): boolean {
    return this.organization === ORGANIZATIONS.RP ||
        this.organization === ORGANIZATIONS.SL ||
        this.organization === ORGANIZATIONS.SH ||
        this.organization === ORGANIZATIONS.ST ||
        this.organization === ORGANIZATIONS.BE ||
        this.organization === ORGANIZATIONS.MV
  }

  isBrandenburg() {
    return this.organization === ORGANIZATIONS.BB;
  }

  supportsMultipleCampaigns(): boolean {
    const product = productForIdentifier(this.config.product_id);
    if (product) {
      return !this.organization.siteConfig.get(product)?.hasSingularCampaign ?? false;
    } else {
      return false
    }
  }

  //Note: Keep logs in but comment out
  doesCurrentDataOverlapWithActiveCampaigns(): boolean {
    const currentStart = moment(this.datetimeStart).valueOf();
    const currentEnd = moment(this.datetimeEnd).valueOf();

    // console.log("DEBUG", `Current Start Millis ${currentStart}`)
    // console.log("DEBUG", `Current End Millis ${currentEnd}`)

    const futureCampaignsWithCurrentExcluded = this.campaigns.filter((campaign: CampaignModel) =>
        //current obviously only exists when editing (!isNew)
        (moment(String(campaign.start)).isAfter() || moment(String(campaign.end)).isAfter()) && (campaign.id != this.CampaignProp?.id)
    )

    for (const futureCampaign of futureCampaignsWithCurrentExcluded) {
      const activeStart = moment(futureCampaign.start).valueOf()
      const activeEnd = moment(futureCampaign.end).valueOf()

      // console.log("DEBUG", `Active Start Millis ${activeStart}`)
      // console.log("DEBUG", `Active End Millis ${activeEnd}`)

      const startDoesIntersect = timeUtil.between(currentStart, activeStart, activeEnd);
      const endDoesIntersect = timeUtil.between(currentEnd, activeStart, activeEnd);

      // console.log("DEBUG", `Does start intersect: ${startDoesIntersect}`)
      // console.log("DEBUG", `Does end intersect: ${endDoesIntersect}`)

      if (startDoesIntersect || endDoesIntersect) {
        return true
      }
    }
    return false
  }

  doDimensMatchWithFileProvided(file: any, dimenToFollow: Dimension): Promise<any> {
    // console.log("DEBUG", "=== doDimensMatchWithFileProvided ===")
    return new Promise((resolve, reject) => {
      const dimens = this.dimens?.find(e => e.name === dimenToFollow.identifier)
      if (dimens) {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e: any) => {
          const image = new Image();
          image.src = e.target.result;

          image.onload = (rs: any) => {
            const height = rs.currentTarget['height'];
            const width = rs.currentTarget['width'];
            if (height != dimens.height || width != dimens.width) {
              reject()
            } else {
              resolve()
            }
          };
        };
      } else {
        resolve()
      }
    })
  }

  isSuperadmin(): boolean {
    return this.user.role.role_id === ROLES.SUPERADMIN.role_id
  }

  requestNewAdImage(identifier: string) {
    const dimen = this.getDimenIfExisting(identifier)
    if (dimen) {
      window.open(`https://picsum.photos/${dimen.width}/${dimen.height}`)
    }
  }

  superAdminRequestNewPhoneImageText(): string {
    return this.config.supports_tablets_images ? 'Kampagnenbild anfordern (Phone)' : 'Kampagnenbild anfordern'
  }
}























import Vue from "vue";
import {Component, Watch} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {VALUES} from "@/constants/values";

const infoModule = namespace('info');

@Component({
  name: 'AppSnackbar'
})
export default class AppSnackbar extends Vue {
  @infoModule.Action('clear') clearSnackbar!: Function;
  @infoModule.State('snackbarVisible') isVisible!: boolean;
  @infoModule.Getter('dialogMessage') message!: string | null;

  timeout = VALUES.SNACKBAR_TIMEOUT;

  get visible() {
    return this.isVisible
  }

  @Watch('visible')
  onShow(visible: boolean) {
    if (visible) {
      setTimeout(() => {
        this.clearSnackbar()
      }, this.timeout);
    }
  }
}

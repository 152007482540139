

















































































































import Vue from "vue";
import {Component, Watch} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {TicketDialogModel} from "@/stores/tickets/types";
import {ImageDimension} from "@/stores/auth/types";

const authModule = namespace('auth');
const ticketModule = namespace('tickets');
const infoModule = namespace('info');

@Component({
  name: 'TicketDialog.vue',
})
export default class LoseDialog extends Vue {
  @authModule.Getter('imageDimensions') dimens!: Array<ImageDimension> | null;

  @ticketModule.State('ticketDialogState') state!: TicketDialogModel;
  @ticketModule.Mutation('dismissTicketDialog') cancelDialog!: Function;
  @ticketModule.Action('create') saveTicket!: Function;
  @ticketModule.Action('update') updateTicket!: Function;
  @ticketModule.Action('deleteImage') deleteImage!: Function;

  //special
  @infoModule.Action('setLoadingDismissalFlag') setLoadingDismissalFlag!: Function;

  loadActive = false;

  description = '';
  headline = '';
  cent_price = 0;
  cent_hauptgewinn = 0;
  chance_hauptgewinn = 0;
  chances_description = '';
  frontImage: any = {};
  backImage: any = {};

  get FrontImageSrc() {
    return this.state?.ticket?.front_image
  }

  get BackImageSrc() {
    return this.state?.ticket?.back_image
  }

  get title() {
    if (!this.state.isNew && this.state.visible) {
      return 'Los bearbeiten'
    } else if (this.state.isNew && this.state.visible) {
      return 'Neues Los'
    } else {
      return ''
    }
  }

  getDimenIfExisting(identifier: string): ImageDimension | undefined {
    return this.dimens?.find(e => e.name === identifier)
  }

  uploadFrontImage(file: File) {
    this.frontImage = file
  }

  uploadBackImage(file: File) {
    this.backImage = file
  }

  onCancel() {
    this.cancelDialog()
  }

  openImage(url: string) {
    window.open(url)
  }

  onConfirm() {
    // special loading indicator handling
    this.setLoadingDismissalFlag();

    if (this.state.isNew) {
      const data = new FormData();
      data.append('description', this.description);
      data.append('headline', this.headline);
      data.append('cent_price', String(this.cent_price));
      data.append('cent_hauptgewinn', String(this.cent_hauptgewinn));
      data.append('chance_hauptgewinn', String(this.chance_hauptgewinn));
      data.append('chances_description', this.chances_description);
      if (this.frontImage) {
        data.append('front_image', this.frontImage)
      }
      if (this.backImage) {
        data.append('back_image', this.backImage)
      }

      this.saveTicket(data)
    } else {
      const data = new FormData();
      data.append('description', this.description);
      data.append('headline', this.headline);
      data.append('cent_price', String(this.cent_price));
      data.append('cent_hauptgewinn', String(this.cent_hauptgewinn));
      data.append('chance_hauptgewinn', String(this.chance_hauptgewinn));
      data.append('chances_description', this.chances_description);
      if (this.frontImage) {
        data.append('front_image', this.frontImage)
      }
      if (this.backImage) {
        data.append('back_image', this.backImage)
      }

      this.updateTicket({formData: data, lot_id: this.state.ticket?.id})
    }
  }

  @Watch('state.visible')
  onShowDialog() {
    if (!this.state.isNew && this.state.visible && this.state.ticket) {
      const los = this.state.ticket;

      this.headline = los?.headline ? los.headline : '';
      this.description = los?.description ? los.description : '';
      this.cent_price = los?.cent_price ? los.cent_price : 0;
      this.cent_hauptgewinn = los?.cent_hauptgewinn ? los.cent_hauptgewinn : 0;
      this.chance_hauptgewinn = los?.chance_hauptgewinn ? los.chance_hauptgewinn : 0;
      this.chances_description = los?.chances_description ? los.chances_description : '';
      this.frontImage = null;
      this.backImage = null;
    } else if (this.state.isNew && this.state.visible) {
      this.headline = '';
      this.description = '';
      this.cent_price = 0;
      this.cent_hauptgewinn = 0;
      this.chance_hauptgewinn = 0;
      this.chances_description = '';
      this.frontImage = null;
      this.backImage = null
    }
  }
}

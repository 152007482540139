import axios, {AxiosResponse} from "axios";
import store from '@/stores/index';
import {VALUES} from "@/constants/values";
import {checkForNetworkError, ERRORS, getErrorMessageForCode} from "@/constants/errors";
import {AuthModel} from "@/stores/auth/types";

export const baseURL = process.env.VUE_APP_API_URL || "https://lottoads-test.mobivention.eu/";

export const variant = process.env.VUE_APP_VARIANT || "test";

export const version = process.env.VUE_APP_VERSION || "<version>";

const apiAxios = axios.create({
    baseURL
});

apiAxios.interceptors.request.use(function (config) {
    // start loading
    store.commit('info/setLoading', true, {root: true});

    const stored = localStorage.getItem(VALUES.PERSISTED_AUTH);
    let authData: AuthModel | null = null;
    let authToken: string | null | undefined = null;
    if (stored) {
        authData = JSON.parse(stored);
        authToken = authData?.auth_token
    }

    // Add auth token if it exists
    if (authToken) {
        const headers = config.headers;
        headers['X-Auth-Token'] = authToken;
        config.headers = headers;
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

apiAxios.interceptors.response.use(
    (response: AxiosResponse) => {
        if (response.status >= 500) {
            return Promise.reject(ERRORS.UNKNOWN.message)
        } else {
            // special flag used before chains of API calls are made
            // resets on each success or error message
            if (!store.state.info.preventLoadingDismissal) {
                setTimeout(() => {
                        store.commit('info/setLoading', false, {root: true});
                    },
                    VALUES.LOADING_DELAY
                );
            }
            return response.data;
        }
    },
    (error: any) => {
        // stop loading
        store.commit('info/setLoading', false, {root: true});
        // don't know if neccessary to do the checks this extreme but to be super safe ;)
        if (error.response && error.response.data && error.response.data.message && error.response.data.message.code) {
            const code = error.response.data.message.code;

            if (code == ERRORS.INVALID_TOKEN.code) {
                store.commit('auth/resetState', false, {root: true});
            }
            const displayMessage = getErrorMessageForCode(code);
            if (displayMessage) {
                return Promise.reject(displayMessage)
            } else {
                return Promise.reject(error.response.data.message)
            }
        } else {
            return Promise.reject(checkForNetworkError(error))
        }
    }
);

export default apiAxios;

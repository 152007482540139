





















import Vue from "vue";
import {Component, Watch} from "vue-property-decorator";
import {namespace} from "vuex-class";

const infoModule = namespace('info');

@Component({
  name: 'InfoDialog'
})
export default class InfoDialog extends Vue {
  @infoModule.State('dialogVisible') isVisible!: boolean;
  @infoModule.Action('clear') clearDialog!: Function;
  @infoModule.Getter('dialogColorForType') dialogColor!: string | null;
  @infoModule.Getter('dialogTitle') title!: string | null;
  @infoModule.Getter('dialogMessage') message!: string | null;

  @Watch('isVisible')
  onVisibilityChanged(val: boolean, oldVal: boolean) {
    if (!val) {
      this.clearDialog()
    }
  }
}

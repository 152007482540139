import {Product, productForIdentifier, PRODUCTS} from "@/enum/Products";
import {GameType, GAMETYPES} from "@/enum/GameTypes";
import {DocumentsDefinition} from "@/stores/documents/types";

export function createMapForOrgMaxFields(orgCode: number, isOnline: boolean): Map<GameType, number> {
    let map: Map<GameType, number> = new Map();

    switch (orgCode) {
        case 2: //ORGANIZATIONS.BY.validCodes: [] //DONE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 15],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.KENO, 6]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 15],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.KENO, 6]
                ]);
            }
            break;
        case 3: //ORGANIZATIONS.BW.validCodes: [] //DONE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 10],
                    [GAMETYPES.KENO, 6]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 10],
                    [GAMETYPES.KENO, 6]
                ]);
            }
            break;
        case 13: //ORGANIZATIONS.BB.validCodes: [] //DONE
            //BB Terminal is defined as service Product
            if (!isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 18],
                    [GAMETYPES.LOTTO_SYSTEM, 4],
                    [GAMETYPES.EUROJACKPOT, 15],
                    [GAMETYPES.KENO, 5]
                ]);
            }
            break;
        case 6: //ORGANIZATIONS.RP.validCodes: [] //DONE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.KENO, 5]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.KENO, 5]
                ]);
            }
            break;
        case 12: //ORGANIZATIONS.SL.validCodes: [] //DONE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.KENO, 5]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 10],
                    [GAMETYPES.KENO, 5]
                ]);
            }
            break;
        case 8: //ORGANIZATIONS.SH.validCodes: [] //DONE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.KENO, 5]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 15],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.KENO, 5]
                ]);
            }
            break;
        case 11: //ORGANIZATIONS.ST.validCodes: [] //DONE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 12],
                    [GAMETYPES.EUROJACKPOT, 10]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 14]
                ]);
            }
            break;
        case 7: //ORGANIZATIONS.BE.validCodes: [] //DONE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.KENO, 5]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.KENO, 8]
                ]);
            }
            break;
        case 16: //ORGANIZATIONS.MV.validCodes: [] //DONE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 12],
                    [GAMETYPES.EUROJACKPOT, 10],
                    [GAMETYPES.KENO, 5]
                ]);
            }
            break;
    }
    return map
}

export function createMapForOrgMaxRuntimes(orgCode: number, isOnline: boolean): Map<GameType, number> {
    let map: Map<GameType, number> = new Map();

    switch (orgCode) {
        case 2: //ORGANIZATIONS.BY
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 15],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.GLUECKSSPIRALE, 52],
                    [GAMETYPES.KENO, 28]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 15],
                    [GAMETYPES.EUROJACKPOT, 12],
                    [GAMETYPES.GLUECKSSPIRALE, 52],
                    [GAMETYPES.KENO, 28]
                ]);
            }
            break;
        case 3: //ORGANIZATIONS.BW
            if (!isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 14],
                    [GAMETYPES.EUROJACKPOT, 10],
                    [GAMETYPES.GLUECKSSPIRALE, 52],
                    [GAMETYPES.KENO, 28]
                ]);
            }
            break;
        case 13: //ORGANIZATIONS.BB
            //BB Terminal is defined as service Product
            if (!isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 5],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 52],
                    [GAMETYPES.KENO, 28]
                ]);
            }
            break;
        case 6: //ORGANIZATIONS.RP
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 8],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 52],
                    [GAMETYPES.KENO, 28]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 8],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 52],
                    [GAMETYPES.KENO, 28]
                ]);
            }
            break;
        case 12: //ORGANIZATIONS.SL
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 10],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 8],
                    [GAMETYPES.KENO, 28]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 8],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 8],
                    [GAMETYPES.KENO, 28]
                ]);
            }
            break;
        case 8: //ORGANIZATIONS.SH
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 10],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 8],
                    [GAMETYPES.KENO, 28]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 8],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 8],
                    [GAMETYPES.KENO, 28]
                ]);
            }
            break;
        case 11: //ORGANIZATIONS.ST
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 8],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 8]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 8],
                    [GAMETYPES.EUROJACKPOT, 8],
                    [GAMETYPES.GLUECKSSPIRALE, 52]
                ]);
            }
            break;
        case 7: //ORGANIZATIONS.BE
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 10],
                    [GAMETYPES.EUROJACKPOT, 10],
                    [GAMETYPES.GLUECKSSPIRALE, 10],
                    [GAMETYPES.KENO, 28]
                ]);
            } else {
                map = new Map([
                    [GAMETYPES.LOTTO, 10],
                    [GAMETYPES.EUROJACKPOT, 10],
                    [GAMETYPES.GLUECKSSPIRALE, 10],
                    [GAMETYPES.KENO, 28]
                ]);
            }
            break;
        case 16: //ORGANIZATIONS.MV
            if (isOnline) {
                map = new Map([
                    [GAMETYPES.LOTTO, 5],
                    [GAMETYPES.EUROJACKPOT, 5],
                    [GAMETYPES.GLUECKSSPIRALE, 5],
                    [GAMETYPES.KENO, 28]
                ]);
            }
            break;
    }
    return map
}

export const ORGANIZATIONS = {
    MOBIVENTION: {
        validCodes: new Map([
            [PRODUCTS.OA, 8652],
            [PRODUCTS.SVA, 8652],
        ]),
        name: "Mobivention GmbH",
        siteConfig: new Map([
                [
                    PRODUCTS.SVA,
                    {
                        fields: createMapForOrgMaxFields(8652, false),
                        runtimes: createMapForOrgMaxRuntimes(8652, false),
                        hasSingularCampaign: false
                    }
                ],
                [
                    PRODUCTS.OA,
                    {
                        fields: createMapForOrgMaxFields(8652, true),
                        runtimes: createMapForOrgMaxRuntimes(8652, true),
                        hasSingularCampaign: false
                    }
                ]
            ]
        ),
        documents: null
    },

    BY: {
        validCodes: new Map([
            [PRODUCTS.OA, 2],
            [PRODUCTS.SVA, 21],
        ]),
        name: "Staatliche Lotterieverwaltung in Bayern",
        siteConfig: new Map([
                [
                    PRODUCTS.SVA,
                    {
                        fields: createMapForOrgMaxFields(2, false),
                        runtimes: createMapForOrgMaxRuntimes(2, false),
                        hasSingularCampaign: true
                    }
                ],
                [
                    PRODUCTS.OA,
                    {
                        fields: createMapForOrgMaxFields(2, true),
                        runtimes: createMapForOrgMaxRuntimes(2, true),
                        hasSingularCampaign: false
                    }
                ]
            ]
        ),
        documents: null
    },

    BW: {
        validCodes: new Map([
            [PRODUCTS.OA, 3],
            [PRODUCTS.SVA, 3],
        ]),
        name: "Staatliche Toto-Lotto GmbH Baden-Württemberg",
        siteConfig: new Map([
                [
                    PRODUCTS.SVA,
                    {
                        fields: createMapForOrgMaxFields(3, false),
                        runtimes: createMapForOrgMaxRuntimes(3, false),
                        hasSingularCampaign: false
                    }
                ],
                [
                    PRODUCTS.OA,
                    {
                        fields: createMapForOrgMaxFields(3, true),
                        runtimes: createMapForOrgMaxRuntimes(3, true),
                        hasSingularCampaign: false
                    }
                ]
            ]
        ),
        documents: null
    },

    BB: {
        validCodes: new Map([
            [PRODUCTS.OA, 13],
            [PRODUCTS.SVA, 13],
        ]),
        name: "Land Brandenburg Lotto GmbH",
        siteConfig: new Map([
                [
                    PRODUCTS.SVA,
                    {
                        fields: createMapForOrgMaxFields(13, false),
                        runtimes: createMapForOrgMaxRuntimes(13, false),
                        hasSingularCampaign: true
                    }
                ],
                [
                    PRODUCTS.OA,
                    {
                        fields: createMapForOrgMaxFields(13, true),
                        runtimes: createMapForOrgMaxRuntimes(13, true),
                        hasSingularCampaign: true
                    }
                ]
            ]
        ),
        //see stores/documents/types.ts DocumentsDefinition, DocumentCategory, DocumentType
        documents: {
            availableCategories: [
                {
                    title: "Broschüren",
                    validTypes: [
                        {
                            key: "B1",
                            title: "Produktbroschüre"
                        },
                        {
                            key: "B2",
                            title: "LOTTO 6aus49 System"
                        },
                        {
                            key: "B3",
                            title: "TOTO Auswahlwette System"
                        },
                        {
                            key: "B4",
                            title: "TOTO Ergebniswette System"
                        }
                    ]
                },
                {
                    title: "Datenschutzerklärung",
                    validTypes: [
                        {
                            key: "D1",
                            title: "Datenschutzerklärung"
                        }
                    ]
                },
                {
                    title: "Impressum",
                    validTypes: [
                        {
                            key: "I1",
                            title: "Impressum"
                        }
                    ]
                },
                {
                    title: "Spielerschutz",
                    validTypes: [
                        {
                            key: "S1",
                            title: "Prävention hat Priorität"
                        },
                        {
                            key: "S2",
                            title: "Jugendschutz"
                        },
                        {
                            key: "S3",
                            title: "Spielersperren und Kundenkartenpflicht"
                        },
                        {
                            key: "S4",
                            title: "Beratung und Hilfe"
                        },
                        {
                            key: "S5",
                            title: "Fragebogen Spielerschutz"
                        }
                    ]
                },
                {
                    title: "Teilnahmebedingungen",
                    validTypes: [
                        {
                            key: "T1",
                            title: "LOTTO 6aus49"
                        },
                        {
                            key: "T2",
                            title: "Eurojackpot"
                        },
                        {
                            key: "T3",
                            title: "GlücksSpirale"
                        },
                        {
                            key: "T4",
                            title: "KENO"
                        },
                        {
                            key: "T5",
                            title: "Spiel77"
                        },
                        {
                            key: "T6",
                            title: "SUPER6"
                        },
                        {
                            key: "T7",
                            title: "Sieger-Chance"
                        },
                        {
                            key: "T8",
                            title: "plus5"
                        },
                        {
                            key: "T9",
                            title: "Dauerspiel"
                        },
                        {
                            key: "T10",
                            title: "Lose"
                        },
                        {
                            key: "T11",
                            title: "Glücksrakete"
                        },
                        {
                            key: "T12",
                            title: "TOTO 13er Ergebniswette"
                        },
                        {
                            key: "T13",
                            title: "TOTO Auswahlwette"
                        }
                    ]
                },
                {
                    title: "Weitere Dokumente",
                    validTypes: [
                        {
                            key: "W1",
                            title: "Kontakt"
                        },
                        {
                            key: "W2",
                            title: "Kundenkarten"
                        },
                        {
                            key: "W3",
                            title: "Dauerspiel"
                        },
                        {
                            key: "W4",
                            title: "Gewinne abholen"
                        },
                        {
                            key: "W5",
                            title: "Über Lotto Brandenburg"
                        }
                    ]
                }
            ]
        }
    },

    // Berlin + Friends
    RP: {
        validCodes: new Map([
            [PRODUCTS.OA, 6],
            [PRODUCTS.SVA, 6],
        ]),
        name: "Lotto Rheinland-Pfalz GmbH",
        siteConfig: new Map([
                [
                    PRODUCTS.SVA,
                    {
                        fields: createMapForOrgMaxFields(6, false),
                        runtimes: createMapForOrgMaxRuntimes(6, false),
                        hasSingularCampaign: false
                    }
                ],
                [
                    PRODUCTS.OA,
                    {
                        fields: createMapForOrgMaxFields(6, true),
                        runtimes: createMapForOrgMaxRuntimes(6, true),
                        hasSingularCampaign: false
                    }
                ]
            ]
        ),
        documents: null
    },

    SL: {
        validCodes: new Map([
            [PRODUCTS.OA, 12],
            [PRODUCTS.SVA, 12],
        ]),
        name: "Saarland Sporttoto GmbH",
        siteConfig: new Map([
                [
                    PRODUCTS.SVA,
                    {
                        fields: createMapForOrgMaxFields(12, false),
                        runtimes: createMapForOrgMaxRuntimes(12, false),
                        hasSingularCampaign: true
                    }
                ],
                [
                    PRODUCTS.OA,
                    {
                        fields: createMapForOrgMaxFields(12, true),
                        runtimes: createMapForOrgMaxRuntimes(12, true),
                        hasSingularCampaign: false
                    }
                ]
            ]
        ),
        documents: null
    },

    SH: {
        validCodes: new Map([
            [PRODUCTS.OA, 8],
            [PRODUCTS.SVA, 8],
        ]),
        name: "NordwestLotto Schleswig-Holstein GmbH & Co. KG",
        siteConfig: new Map([
                [
                    PRODUCTS.SVA,
                    {
                        fields: createMapForOrgMaxFields(8, false),
                        runtimes: createMapForOrgMaxRuntimes(8, false),
                        hasSingularCampaign: false
                    }
                ],
                [
                    PRODUCTS.OA,
                    {
                        fields: createMapForOrgMaxFields(8, true),
                        runtimes: createMapForOrgMaxRuntimes(8, true),
                        hasSingularCampaign: false
                    }
                ]
            ]
        ),
        documents: null
    },

    ST: {
        validCodes: new Map([
            [PRODUCTS.OA, 11],
            [PRODUCTS.SVA, 11],
        ]),
        name: "Lotto-Toto GmbH Sachsen-Anhalt",
        siteConfig: new Map([
                [
                    PRODUCTS.SVA,
                    {
                        fields: createMapForOrgMaxFields(11, false),
                        runtimes: createMapForOrgMaxRuntimes(11, false),
                        hasSingularCampaign: true
                    }
                ],
                [
                    PRODUCTS.OA,
                    {
                        fields: createMapForOrgMaxFields(11, true),
                        runtimes: createMapForOrgMaxRuntimes(11, true),
                        hasSingularCampaign: false
                    }
                ]
            ]
        ),
        documents: null
    },

    BE: {
        validCodes: new Map([
            [PRODUCTS.OA, 7],
            [PRODUCTS.SVA, 7],
        ]),
        name: "Deutsche Klassenlotterie Berlin",
        siteConfig: new Map([
                [
                    PRODUCTS.SVA,
                    {
                        fields: createMapForOrgMaxFields(7, false),
                        runtimes: createMapForOrgMaxRuntimes(7, false),
                        hasSingularCampaign: false
                    }
                ],
                [
                    PRODUCTS.OA,
                    {
                        fields: createMapForOrgMaxFields(7, true),
                        runtimes: createMapForOrgMaxRuntimes(7, true),
                        hasSingularCampaign: false
                    }
                ]
            ]
        ),
        documents: null
    },

    MV: {
        validCodes: new Map([
            [PRODUCTS.OA, 16],
            [PRODUCTS.SVA, 16],
        ]),
        name: "Verwaltungsgesellschaft Lotto und Toto in Mecklenburg-Vorpommern mbH",
        siteConfig: new Map([
                [
                    PRODUCTS.SVA,
                    {
                        fields: createMapForOrgMaxFields(16, false),
                        runtimes: createMapForOrgMaxRuntimes(16, false),
                        hasSingularCampaign: false
                    }
                ],
                [
                    PRODUCTS.OA,
                    {
                        fields: createMapForOrgMaxFields(16, true),
                        runtimes: createMapForOrgMaxRuntimes(16, true),
                        hasSingularCampaign: false
                    }
                ]
            ]
        ),
        documents: null
    }
};

export interface Organization {
    validCodes: Map<Product, number>;
    name: string;
    siteConfig: Map<Product, OnSiteConfig>;
    documents: DocumentsDefinition | null;
}

export interface OnSiteConfig {
    fields: Map<GameType, number>;
    runtimes: Map<GameType, number>;
    // When true, campaigns cannot overlap in their defined time
    // Because of already variating implementations between products in BY
    // This has to be in this config as it is Product specific (Org > Product (OnSiteConfig))
    hasSingularCampaign: boolean;
}

export function getOrgForName(value: string): Organization {
    switch (value) {
        case ORGANIZATIONS.BY.name:
            return ORGANIZATIONS.BY;
        case ORGANIZATIONS.BW.name:
            return ORGANIZATIONS.BW;
        case ORGANIZATIONS.BB.name:
            return ORGANIZATIONS.BB;
        case ORGANIZATIONS.RP.name:
            return ORGANIZATIONS.RP;
        case ORGANIZATIONS.SL.name:
            return ORGANIZATIONS.SL;
        case ORGANIZATIONS.SH.name:
            return ORGANIZATIONS.SH;
        case ORGANIZATIONS.ST.name:
            return ORGANIZATIONS.ST;
        case ORGANIZATIONS.BE.name:
            return ORGANIZATIONS.BE;
        case ORGANIZATIONS.MV.name:
            return ORGANIZATIONS.MV;
        default:
            return ORGANIZATIONS.MOBIVENTION
    }
}

export function getOrgForCode(value: number, productIdentifier: string): Organization {
    const product = productForIdentifier(productIdentifier);

    if (product) {
        if (ORGANIZATIONS.BY.validCodes.get(PRODUCTS.SVA) == value || ORGANIZATIONS.BY.validCodes.get(PRODUCTS.OA) == value) {
            return ORGANIZATIONS.BY
        }
        if (ORGANIZATIONS.BW.validCodes.get(product) == value) {
            return ORGANIZATIONS.BW
        }
        if (ORGANIZATIONS.BB.validCodes.get(product) == value) {
            return ORGANIZATIONS.BB
        }
        if (ORGANIZATIONS.RP.validCodes.get(product) == value) {
            return ORGANIZATIONS.RP
        }
        if (ORGANIZATIONS.SL.validCodes.get(product) == value) {
            return ORGANIZATIONS.SL
        }
        if (ORGANIZATIONS.SH.validCodes.get(product) == value) {
            return ORGANIZATIONS.SH
        }
        if (ORGANIZATIONS.ST.validCodes.get(product) == value) {
            return ORGANIZATIONS.ST
        }
        if (ORGANIZATIONS.BE.validCodes.get(product) == value) {
            return ORGANIZATIONS.BE
        }
        if (ORGANIZATIONS.MV.validCodes.get(product) == value) {
            return ORGANIZATIONS.MV
        }
    }

    return ORGANIZATIONS.MOBIVENTION
}

export function getCodeForOrganizationAndProduct(org: Organization, productIdentifier: string, variant: string): number | undefined {
    const product = productForIdentifier(productIdentifier);

    if (product) {
        switch (org) {
            case ORGANIZATIONS.BY:
                if (variant === "live") {
                    return 2
                } else {
                    return ORGANIZATIONS.BY.validCodes.get(product)
                }
            case ORGANIZATIONS.BW:
                return ORGANIZATIONS.BW.validCodes.get(product)
            case ORGANIZATIONS.BB:
                return ORGANIZATIONS.BB.validCodes.get(product)
            case ORGANIZATIONS.RP:
                return ORGANIZATIONS.RP.validCodes.get(product)
            case ORGANIZATIONS.SL:
                return ORGANIZATIONS.SL.validCodes.get(product)
            case ORGANIZATIONS.SH:
                return ORGANIZATIONS.SH.validCodes.get(product)
            case ORGANIZATIONS.ST:
                return ORGANIZATIONS.ST.validCodes.get(product)
            case ORGANIZATIONS.BE:
                return ORGANIZATIONS.BE.validCodes.get(product)
            case ORGANIZATIONS.MV:
                return ORGANIZATIONS.MV.validCodes.get(product)
        }
    }
    return ORGANIZATIONS.MOBIVENTION.validCodes.get(PRODUCTS.SVA)
}

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from "vuetify/src/locale/de";

//For future https://vuetifyjs.com/en/getting-started/frequently-asked-questions/#questions
//-> When adding typescript - Error: Could not find a declaration file for module 'vuetify/lib’

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {de},
        current: "de",
    },
    theme: {
        themes: {
            light: {
                primary: "#D22321",     //lotto red
                secondary: "#F7DC16",   //lotto yellow
                accent: "#21d0d2",      //complementary accent to lotto red
                error: "#E57373",
                warning: "#FFB74D",
                info: "#4DD0E1",
                success: "#81B219",
                android: "#3DDC84",
                apple: "#000000"
            },
        },
    },
    icons: {
        iconfont: "mdi"
    },
});


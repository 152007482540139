














































































import Vue from "vue";
import {Component, Watch} from "vue-property-decorator";
import {namespace} from "vuex-class";
import vueDropzone from 'vue2-dropzone'
import VDatetimePicker from "@/components/datetimepicker/DatetimePicker.vue";
import moment from 'moment'
import {DocumentDialogModel} from "@/stores/documents/types";
import timeUtil from "@/utils/time"
import {Organization} from "@/enum/Organizations";

const authModule = namespace('auth');
const documentsModule = namespace('documents');
const infoModule = namespace('info');

@Component({
  name: 'DocumentDialog',
  components: {
    vueDropzone,
    VDatetimePicker
  }
})
export default class DocumentDialog extends Vue {
  @authModule.Getter('gesellschaft') organization!: Organization;

  @documentsModule.State('docDialogState') state!: DocumentDialogModel;
  @documentsModule.Mutation('dismissDocumentDialog') cancelDialog!: Function;
  @documentsModule.Action('upload') uploadDoc!: Function;
  @documentsModule.Action('create') saveDoc!: Function;
  @documentsModule.Action('update') updateDoc!: Function;

  @infoModule.State('loading') loading!: boolean;

  loadActive = false;

  uploadData: any | null = null;
  category = '';
  title = '';
  key = '';

  datetimeStart: Date | null = null;
  datetimeEnd: Date | null = null;

  get categories() {
    if (this.organization.documents?.availableCategories) {
      return this.organization.documents.availableCategories
    }
    return []
  }

  get dialogTitle() {
    if (!this.state.isNew && this.state.visible) {
      return 'Dokument bearbeiten'
    } else if (this.state.isNew && this.state.visible) {
      return 'Neues Dokument'
    } else {
      return ''
    }
  }

  get keys() {
    if (this.category && this.organization.documents?.availableCategories) {
      return this.organization.documents?.availableCategories.find(category => category.title === this.category)?.validTypes
    } else {
      return []
    }
  }

  get disabled() {
    return !this.state.isNew;
  }

  onCancel() {
    //this.$refs.myVueDropzone.removeAllFiles()
    this.cancelDialog();
    this.uploadData = null;
    this.category = '';
    this.title = '';
    this.key = '';
  }

  onConfirm() {
    if (this.state.isNew) {
      const formData = new FormData();
      //Single selection so this is safe
      if (this.uploadData) {
        formData.append("document", this.uploadData);
      }
      this.uploadDoc(formData).then((res: any) => {
        const data = {
          'key': this.key,
          'filename': res.filename,
          'title': this.title,
          'category': this.category,
          'publish_at': this.datetimeStart ? timeUtil.formattedDate(String(this.datetimeStart)) : null,
          'publish_until': this.datetimeEnd ? timeUtil.formattedDate(String(this.datetimeEnd)) : null
        };
        this.saveDoc(data).then(() => {
          this.onCancel()
        });
      }).catch((err: any) => {
        //
      })
    } else if (!this.state.isNew) {
      const url = this.state.document?.src;
      const urlStripped = url?.substr(url.indexOf('file/') + 5, url?.length);

      if (this.datetimeStart && this.datetimeStart.toDateString() != '') {
        this.datetimeStart = moment(String(this.datetimeStart)).toDate()
      }

      if (this.datetimeEnd && this.datetimeEnd.toDateString() != '') {
        this.datetimeEnd = moment(String(this.datetimeEnd)).toDate()
      }

      const data = {
        'filename': urlStripped,
        'publish_at': this.datetimeStart ? timeUtil.formattedDate(String(this.datetimeStart)) : null,
        'publish_until': this.datetimeEnd ? timeUtil.formattedDate(String(this.datetimeEnd)) : null
      };
      this.updateDoc(data).then(() => {
        this.onCancel()
      });
    }
  }

  @Watch('state.visible')
  onShowDialog() {
    if (!this.state.isNew) {
      if (this.state.document?.publish_at) {
        this.datetimeStart = new Date(this.state.document?.publish_at);
      } else {
        this.datetimeStart = null
      }

      if (this.state.document?.publish_until) {
        this.datetimeEnd = new Date(this.state.document?.publish_until);
      } else {
        this.datetimeEnd = null
      }

      if (this.state.document?.category) {
        this.category = this.state.document?.category
      }
      if (this.state.document?.key) {
        this.key = this.state.document?.key
      }
      if (this.state.document?.title) {
        this.title = this.state.document?.title
      }
    }
  }

  @Watch('loading')
  onLoadingChanged(val: boolean, oldVal: boolean) {
    this.loadActive = val
  }

  @Watch('category')
  onCategoryChange(val: string) {
    this.category = val
  }

  @Watch('key')
  onKeyChange(documentKey: string) {
    if (this.category && this.organization.documents?.availableCategories) {
      this.title = this.findTitle(documentKey)
    }
  }

  findTitle(documentKey: string): string {
    if (this.organization.documents?.availableCategories) {
      for (const category of this.organization.documents?.availableCategories) {
        for (const document of category.validTypes) {
          if (document.key === documentKey) {
            return document.title
          }
        }
      }
    }
    return ''
  }

  hasDocumentDefinitions(): boolean {
    return this.organization.documents?.availableCategories !== undefined
  }
}

export const PRODUCTS = {
    SVA: {name: 'service'},
    OA: {name: 'online'},
};

export interface Product {
    name: string;
}

export function productForIdentifier(name: string): Product | null {
    switch (name) {
        case PRODUCTS.SVA.name:
            return PRODUCTS.SVA;
        case PRODUCTS.OA.name:
            return PRODUCTS.OA;
        default:
            return null
    }
}
